import * as React from "react";
import BinApiService from "src/api/BinApiService";
import BinDTO from "src/models/BinDTO";
import BinHistoryDataDTO from "src/models/BinHistoryDataDTO";
// import BinHistoryPointDTO from 'src/models/BinHistoryPointDTO';
import MoistureContentSnapshotDTO from "src/models/MoistureContentSnapshotDTO";
import FillingForcast from "./FilingForecast";
import BinInfoDTO from "src/models/BinInfoDTO";
// import BinVisual from './BinVisual';
import "chart.js/auto";
import { Chart as CHARTJS } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { Line } from "react-chartjs-2";
import * as z from "zod";
import { RestartDriStackModuleButton } from "src/pages/admin/RestartModuleButton";
// @ts-ignore
import autocolors from "chartjs-plugin-autocolors";
import {
    Alert,
    Col,
    Layout,
    Row,
    Table,
    Descriptions,
    Skeleton,
    Result,
    DatePicker,
    Checkbox,
    Input,
    Form,
    InputNumber,
    Button,
    Steps,
    Select,
    Spin,
    // Tag,
    message,
    Popconfirm,
    Collapse,
    Space,
    FormInstance,
    Tag,
    Tabs,
    TabsProps,
    Switch,
    Typography,
    Tooltip,
    Divider,
    Popover,
    Segmented,
    ConfigProvider,
    Radio,
    Badge,
    Card
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
    BinVisualThree,
    ChangeHeaterModeModal,
    CompressorCard,
    DISABLED_TAG_COLOR,
    OperatingMode,
    PANEL_STYLE,
    binConfiguredWithHeaters
} from "./BinVisualThree";
import EnterpriseCropDataDTO from "src/models/EnterpriseCropDataDTO";
import { isNumber, round } from "lodash";
import EnterpriseApiService from "src/api/EnterpriseApiService";
import Modal from "antd/lib/modal/Modal";
import { RequiredRule } from "src/consts/FormConstants";
import {
    PlusOutlined,
    PauseOutlined,
    WarningTwoTone,
    LoadingOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CaretRightFilled,
    StepBackwardFilled,
    RedoOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
    WarningFilled
} from "@ant-design/icons";
import RoleUtil from "../../../utils/RoleUtil";
import Role from "../../../consts/Role";
import { RouteComponentProps, useHistory, useLocation, withRouter } from "react-router-dom";
import { PopconfirmProps } from "antd/lib/popconfirm";
import ErrorDTO from "src/models/ErrorDTO";
import ErrorPriority from "src/consts/ErrorPriority";
import ErrorID from "src/consts/ErrorID";
import HeaterMode from "src/consts/HeaterMode";
import { formatHeaterMode } from "src/utils/formatting";
import { UnloadInfoTable, UnloadRow } from "./UnloadInfo";
import { UnloadForm, UnloadFormSubmitValues } from "./UnloadForm";
import { deviceQueryKeys, getUserTimezoneOffset } from "../HomeScreen/BinDetails";
import { FillLoadTable, ReviewLoadTable } from "./LoadTables";
import Routes from "src/consts/Routes";
import dayjs, { Dayjs } from "dayjs";
import { WeatherMonitor } from "src/pages/features/WeatherMonitorAntdOnly";
import { ApiError } from "src/api/ApiResultHandler";
import ManualFanMode from "src/consts/ManualFanMode";
import { fillEmpty, ReportedFormFanSettings, SettingsFormValues } from "src/pages/features/WeatherMonitorForm";
import { ManualFanSettingsModal } from "../ManualFanSettingsModal";
import { AutomationType, selectAutomationType } from "src/utils/deriveAutomationType";
import { useCallback, useEffect, useReducer } from "react";
import { useForm, useWatch } from "antd/es/form/Form";
import { useQuery } from "@tanstack/react-query";
import { useBinStateFromDevice } from "src/queries/useBinStateFromDevice";
import { useBinStateFromAzure } from "src/queries/useBinStateFromAzure";

import { HubConnectionBuilder, HubConnectionState, HubConnection, LogLevel } from "@microsoft/signalr";
import { queryClient } from "src";
import { CameraImage } from "../Camera/CameraImage";
import FanOnAllowedTimeRequestDTO from "src/models/FanOnAllowedTimeRequestDTO";
import { HeaterControls, HeaterMcReductionHelpText, formatBool, formatNumber } from "./HeaterControls";
import { CompressorControls } from "./CompressorControls";
import GrainType from "src/consts/GrainType";
import { useForm as useFormRHF } from "react-hook-form";
import { FormItem } from "react-hook-form-antd";
import { DryLayerAdjust, DryLayerAdjustHelpText, LayerAdjustment, LayerAdjustmentList } from "./DryLayerAdjust";
import { UploadAndRunRoutine, UploadRoutine } from "src/pages/shared/UploadFile";

const { Item } = Descriptions;
const { Step } = Steps;
import { theme } from "antd";
import { DryModeHelpText, FillModeHelpText, IdleHelpText, StorageModeHelpText, UnloadModeHelpText, UserControlModeHelpText } from "./ModeHelpTexts";
import { hasRangeFinder } from "src/pages/features/shared";
import { RoutineList } from "src/pages/shared/RoutineList";
import { ManualRoutineControl } from "./ManualRoutineControl";
import { NewValveDisplay } from "./NewValveDisplay";
import { downloadAsciiBinState } from "./BinVisualThree";
import { BinStatusCard } from "./BinStatusCard";

import { ChartsTab, FanHeaterChangeBackgroundLegend } from "./ChartsTab";
import { fanOnBackgroundColor, FanPausedBackgroundColor, heaterOnBackgroundColor } from "../charts/shared";
import { getDefaultTemperatureSensorType } from "src/pages/shared/binDTOUtils";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import DeviceConnectionState from "src/consts/DeviceConnectionState";
import { BinOfflineIndicator } from "../BinOfflineIndicator";
import { BinDTOContext } from "src/queries/BinDTOContext";
import { HotspotIndicator } from "./HotspotIndicator";
import { useUploadBinStateToAzure } from "src/queries/useUploadBinStateToAzure";

const { useToken } = theme;

export const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
};

export const PANEL_KEYS_DEFAULT_EXPANDED = ["3D", "fill order", "unload order", "tables"];

export const PopconfirmYesNo = (props: PopconfirmProps) => {
    const okText = props.okText ?? "Yes";
    const cancelText = props.cancelText ?? "No";
    return (
        <Popconfirm okText={okText} cancelText={cancelText} {...props}>
            {props.children}
        </Popconfirm>
    );
};

export const heaterRecommendedTempFormatted = (bin: BinDTO | null | undefined): string => {
    const heaterMinRecomTemp = bin?.grain?.heaterMinRecomTemp;
    const heaterMaxRecomTemp = bin?.grain?.heaterMaxRecomTemp;

    if (heaterMinRecomTemp == null || heaterMaxRecomTemp == null) {
        return "-";
    }

    return `${heaterMinRecomTemp} - ${heaterMaxRecomTemp} °F`;
};

export type InnerErrorList = Record<ErrorID, ErrorDTO>;
export type MappingErrorList = Partial<InnerErrorList>;

export interface PendingLoadDeletes {
    [key: string]: {};
}

export interface PendingUnloadDeletes {
    [key: string]: {};
}

const schema = z.object({
    grainType: z.string()
});

interface State {
    loading: boolean;
    selectedBinId?: number | null;
    tableLoading: boolean;
    binName: string;
    binInfo: BinInfoDTO;
    bin?: BinDTO;
    warningPopup: boolean;
    moistureSnapshotData: MoistureContentSnapshotDTO[];
    //targetValue: number;
    offline: boolean;
    fillStart: boolean;
    emptyVisible: boolean;
    addFillVisible: boolean;
    addUnloadVisible: boolean;
    pendingAddFill: boolean;
    pendingAddUnload: boolean;
    pendingDeleteLoad: PendingLoadDeletes;
    pendingDeleteUnload: PendingUnloadDeletes;
    fillMode: boolean;
    graphData: any[] | undefined;
    binStatsData: any[] | undefined;
    loads: Load[];
    currentStep: number;
    newBatchCheck: boolean;
    endFillVisible: boolean;
    buttonLoadingFeedBack:
    | "Idle"
    | "Fill"
    | "Done"
    | "Dry"
    | "Pause"
    | "Resume"
    | "retFill"
    | "Storage"
    | "Unload"
    | "StartManualDry"
    | "StopManualDry"
    | "EnteringManualMode"
    | "ExitingManualMode"
    | "ExitingFailSafe";
    batchEnd: boolean;
    forcedNewBatch: boolean;
    mcChartOffline: boolean;
    refreshTime: Dayjs | null;
    miscBinInfoTableData: any[];
    topStackTableInfo: TopTableData[];
    topStackTableAvgInfo: TopTableAvgData[];
    layerAvgTableInfo: LayerAvgTableData[];
    errorList: MappingErrorList | null;
    activeErrors: MappingErrorList;
    criticalErrors: MappingErrorList;
    warningErrors: MappingErrorList;
    fetchingBinFromDevice: boolean;
    isOpenHeaterChange: boolean;
    showManualFanSettingsModal: boolean;
    showStorageDialog: boolean;
    showAlertsHistoryTable: boolean;
    addLoadVisible: boolean;
    // show the form for settings required to enter manual mode
    showManualEnterModal: boolean;
    showChangeGrainType: boolean;
}

interface FillValues {
    grainType: string | null;
    targetMC: number | string | null;
    heaterMode: string | null;
    currentValue: number | null;
    estimatedTotalBu: number | null;
}
// import RoleUtil from 'src/utils/RoleUtil';
// import Role from 'src/consts/Role';
const { Column } = Table;

// @ts-ignore unused
export interface HistoryState {
    binInfo?: BinInfoDTO;
}

export type binRouterProps = RouteComponentProps<{}, {}, HistoryState>;

export interface Load {
    key: number;
    loadTime: string;
    eventType: string | null;
    estimatedBu: number;
    srcOrDest: string | null;
    MC: number | null;
}

interface TopTableData {
    stackID: string;
    topRH: number | null;
    topTemp: number | null;
    topAH: number | null;
    topDP: number | null;
    topMCPercent: number | null;
}

export const getCurrentModeDescription = (binDTO: BinDTO | null | undefined): string => {
    if (binDTO?.operatingMode == null) {
        return "";
    }

    let op = binDTO?.operatingMode?.toString?.() ?? "";
    switch (binDTO?.operatingMode) {
        case 0:
            op = "Idle";
            break;
        case 1:
            op = "Fill & Aerate";
            break;
        case 2:
            op = "Pre Dry";
            break;
        case 3:
            op = "Dry";
            break;
        case 31:
            op = "Top Dry";
            break;
        case 20:
            op = "User Control";
            break;
        case 101:
            op = "Self Check";
            break;
        case 100:
            op = "Fail Safe";
            break;
        case 4:
            op = "Storage";
            break;
        case 5:
            op = "Unload";
            break;
        default:
            op = `Mode - ${binDTO?.operatingMode}`;
            break;
    }
    return op;
};

// interface TopStackTableProps {
//     tableData: TopTableData[],
// }

// const TopStackTable = (props: TopStackTableProps) => {

//     return <>
//         <Card
//             size="small"
//             bodyStyle={{ padding: 0, paddingTop: 10 }}
//             headStyle={{ background: '#939393' }}
//             title="Top of Stack Moisture & Temperature data">
//             <Table size="small" dataSource={props.tableData} pagination={false} showHeader={true}>
//                 <Column title="Stack" dataIndex="stackID" key="stackID" />
//                 <Column title="Temperature (°F)" dataIndex="topTemp" key="topTemp" />

//                 <Column title="Relative Humidity (%)" dataIndex="topRH" key="RelativeHumidity" responsive={['md']} />
//                 <Column title="Dew Point (°F)" dataIndex="topDP" key="TopDewPoint" responsive={['md']} />
//                 <Column title="Air Moisture Content (%)" dataIndex="topMCPercent" key="topMCPercent" />
//             </Table>
//         </Card>
//     </>
// }

export const warningIconColor = "#FFBF00";

interface TopStackTableAvgProps {
    tableData: TopTableAvgData[];
}

interface TopTableAvgData {
    topRH: number | null;
    topTemp: number | null;
    topAH: number | null;
    topDP: number | null;
    topMCPercent: number | null;
}

const TopStackTableAvg = (props: TopStackTableAvgProps) => {
    return (
        <>
            <Card size="small" bodyStyle={{ padding: 0 }} title="Headspace Sensor Readings">
                <Table size="small" dataSource={props.tableData} pagination={false} showHeader={true}>
                    <Column
                        title="Temp (°F)"
                        ellipsis
                        dataIndex="topTemp"
                        key="topTemp"
                        render={value => {
                            return formatNumber(value, { decimalPlaces: 0, filler: "" });
                        }}
                    />

                    {/* <Column title="DP (°F)" dataIndex="topDP" key="TopDewPoint" responsive={['md']}
                render={(value) => {
                    return formatNumber(value, {decimalPlaces: 0, filler: ""});
                }}
                 /> */}

                    <Column
                        title="RH (%)"
                        align="center"
                        dataIndex="topRH"
                        key="RelativeHumidity"
                        render={value => {
                            return formatNumber(value, { decimalPlaces: 0, filler: "" });
                        }}
                    />

                    <Column
                        title="EMC (%)"
                        dataIndex="topMCPercent"
                        key="topMCPercent"
                        render={value => {
                            return formatNumber(value, { decimalPlaces: 1, filler: "" });
                        }}
                    />
                </Table>
            </Card>
        </>
    );
};

interface LayerAvgTableProps {
    tableData: LayerAvgTableData[];
    binDTO: BinDTO;
}

interface LayerAvgTableData {
    layerNumber: number;
    RH: number | null;
    tempF: number | null;
    //AH: number | null,
    dewPointF: number | null;
    MCPercent: number | null;
}

const LayersAverageTable = (props: LayerAvgTableProps) => {
    return (
        <>
            <Card
                size="small"
                style={{ paddingTop: "10px" }}
                // bodyStyle={{ paddingTop: "10px" }}
                title="Layer Sensor Readings"
            >
                <Table size="small" dataSource={props.tableData} pagination={false} showHeader={true}>
                    <Column title="Layer" dataIndex="layerNumber" key="layerNumber"
                        render={(value => {
                            return <Space>
                                <Typography.Text>{value}</Typography.Text>
                                <HotspotIndicator layerNumber={value} />
                            </Space>
                        })}
                    />
                    <Column
                        title="Temp (°F)"
                        ellipsis
                        dataIndex="tempF"
                        key="tempF"
                        render={value => (
                            <Space direction="horizontal" size="small" wrap>
                                {formatNumber(value, { decimalPlaces: 0, filler: "" })}
                                {value != null && props.binDTO?.plenumAir?.dp! > value && (
                                    <div>
                                        <Tooltip
                                            title={`Layer at risk of condensation because the grain temperature is below the plenum dew point (${formatNumber(
                                                props.binDTO?.plenumAir?.dp!,
                                                { decimalPlaces: 0, filler: "", suffix: "°F" }
                                            )}).`}
                                        >
                                            <WarningFilled twoToneColor="#FFBF00" color="#FFBF00" style={{ color: "#FFBF00" }} />
                                        </Tooltip>
                                    </div>
                                )}
                            </Space>
                        )}
                    />
                    {/* <Column title="DP (°F)" dataIndex="dewPointF" key="DewPoint" responsive={['md']}
                    render={(value) => formatNumber(value, {decimalPlaces: 0, filler: ""})}
                 /> */}
                    <Column
                        title="RH (%)"
                        dataIndex="RH"
                        key="RelativeHumidity"
                        render={value => formatNumber(value, { decimalPlaces: 0, filler: "" })}
                    />

                    <Column
                        title="MC (%)"
                        dataIndex="MCPercent"
                        key="MCPercent"
                        render={value => formatNumber(value, { decimalPlaces: 1, filler: "" })}
                    />
                </Table>
            </Card>
        </>
    );
};
interface RefreshButtonProps {
    refresh: (event: any) => void;
    disabled: boolean;
    loading: boolean;
}
export const RefreshButton = (props: RefreshButtonProps) => {
    return (
        <Button ghost={false} type="primary" size="small" disabled={props.disabled} onClick={props.refresh}>
            <RedoOutlined spin={props.loading} />
        </Button>
    );
};

export const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 }
};
export const layout2 = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 }
};

type binStatsPageProps = binRouterProps & {
    binInfo: BinInfoDTO;
};

enum NewHeaterMode {
    OFF = "OFF",
    ON = "ON",
    AUTO = "AUTO"
}

interface FillFormValues {
    newBatch: boolean;
    grainType: string;
    emcMin: number;
    emcMax: number;
    tempMin: number;
    tempMax: number;
}

interface AddLoadFormValues {
    estimatedBu: number;
    loadTime: Dayjs;
    source: string;
    initialMC: number;
}

interface DryFormValues {
    grainType: string;
    emcMin: number;
    emcMax: number;
    tempMin: number;
    tempMax: number;
    heaterMode: NewHeaterMode;
    maxLayerGrainTemperature: number;
    layers: LayerAdjustment[];
    heaterPlenumMcOffset: number;
}

interface StorageFormValues {
    emcMin: number;
    emcMax: number;
    tempMin: number;
    tempMax: number;
    fanTimerWeeklyHours: number;
}

interface EnterManualFormValues {
    fanTimerHours: number;
}

interface ChangeGrainTypeFormValues {
    grainType: GrainType;
}

enum CurrentStep {
    Idle = 0,
    Fill = 1,
    Dry = 2,
    Storage = 3,
    Unload = 4,
    Manual = 20,
    FailSafe = 100,
    SelfCheck = 101,
    Unknown = -2
}

const BinStatsPage = (props: binStatsPageProps) => {
    // private averTemps: number[];
    const divRef = React.useRef<HTMLDivElement>(null);
    const [startFillForm] = useForm<FillFormValues>();
    const startFillFormValues = useWatch([], startFillForm);
    const [addLoadForm] = useForm<AddLoadFormValues>();
    //private addUnloadForm = React.createRef<FormInstance>();
    const [startDryForm] = useForm<DryFormValues>();
    const startDryFormValues = useWatch([], startDryForm);
    const history = useHistory<HistoryState>();
    const location = useLocation();
    const [storageForm] = useForm<StorageFormValues>();
    const storageFormValues = useWatch([], storageForm);
    const [startManualForm] = useForm<EnterManualFormValues>();

    // const binQuery = useBinStateFromDevice({azureDeviceID: props.binInfo?.deviceId, binId: props.binInfo?.id!, userTimeZoneOffset: getUserTimezoneOffset()}, {enabled: true, staleTime: Infinity});
    const binQueryAzure = useBinStateFromAzure(
        { azureDeviceID: props.binInfo?.deviceId, binId: props.binInfo?.id!, userTimeZoneOffset: getUserTimezoneOffset() },
        { enabled: false, staleTime: Infinity }
    );

    // useEffect(() => {
    //     console.log("updated: Azure binDTO data react query", binQueryAzure.data);
    // }, [binQueryAzure.data]);

    const uploadBinStateToAzureMutation = useUploadBinStateToAzure(props.binInfo?.deviceId!);

    const initialFillValues: FillValues = {
        grainType: null,
        targetMC: null,
        heaterMode: null,
        currentValue: null,
        estimatedTotalBu: null
    };

    const [fillValues, setFillValues] = useReducer(
        (state: FillValues, newState: Partial<FillValues>) => ({ ...state, ...newState } as FillValues),
        initialFillValues
    );

    // Note, binInfo and
    const [state, setState] = useReducer((state: State, newState: Partial<State>) => ({ ...state, ...newState } as State), {
        loading: true,
        tableLoading: true,
        binName: "",
        // bad: skipping casts. check here for crashes
        // bad: passing state to props
        binInfo: props?.binInfo as BinInfoDTO,
        // bad: skipping casts. check here for crashes
        warningPopup: false,
        // bad: skipping casts. check here for crashes
        moistureSnapshotData: [],
        // bad: skipping casts. check here for crashes
        //targetValue: state?.targetValue as number,
        offline: false,
        fillStart: false,
        emptyVisible: false,
        addFillVisible: false,
        addUnloadVisible: false,
        pendingAddFill: false,
        pendingDeleteLoad: {},
        pendingAddUnload: false,
        pendingDeleteUnload: {},
        graphData: undefined,
        binStatsData: undefined,
        fillMode: false,
        currentStep: -2,
        loads: [],
        newBatchCheck: false,
        endFillVisible: false,
        buttonLoadingFeedBack: "Done",
        batchEnd: false,
        forcedNewBatch: false,
        mcChartOffline: false,
        refreshTime: null,
        showAlertsHistoryTable: false,
        miscBinInfoTableData: [],
        topStackTableInfo: [],
        topStackTableAvgInfo: [],
        layerAvgTableInfo: [],
        errorList: null,
        activeErrors: {},
        warningErrors: {},
        criticalErrors: {},
        fetchingBinFromDevice: false,
        isOpenHeaterChange: false,
        showManualFanSettingsModal: false,
        showStorageDialog: false,
        addLoadVisible: false,
        showManualEnterModal: false,
        showChangeGrainType: false
    });

    // On load, have device upload new BinState to DB
    useEffect(() => {
        uploadBinStateToAzureMutation.mutate(undefined, {
            onError(error, variables, context) {
                console.error("Failed requesting device to upload new BinState on page load", error);
            },
        });
    }, []);

    const changeGrainTypeDefaultValues = { grainType: state.bin?.grain?.grainType ?? GrainType.Corn };

    const changeGrainTypeFormMethods = useFormRHF<ChangeGrainTypeFormValues>({
        defaultValues: changeGrainTypeDefaultValues,
        values: changeGrainTypeDefaultValues,
        resetOptions: {
            keepDirtyValues: true
        }
    });

    const toggleManualStartModal = (show: boolean) => {
        setState({ showManualEnterModal: show });
    };

    const toggleChangeGrainTypeModal = (show: boolean) => {
        setState({ showChangeGrainType: show });
    };

    const updateCurrentStep = useCallback(
        (bin: BinDTO) => {
            const { currentBatch } = bin!;
            if (currentBatch?.batchNumber) {
                const targetMC = currentBatch.targetMC || null;
                const grainType = currentBatch.grainType || null;
                const heaterMode = currentBatch.heaterMode ?? null;
                const estimatedTotalBu = bin?.estimatedCurrentBu || null;
                setFillValues({ grainType: grainType, targetMC: targetMC, heaterMode: heaterMode, estimatedTotalBu: estimatedTotalBu });

                let loadsAndUnloads: Load[] = [];
                currentBatch.loadsUnloads?.forEach(load => {
                    var eventType = load.eventType === "AddLoad" ? "Load" : "Unload";
                    var estimatedBu = 0;
                    if (load.amountIn) {
                        estimatedBu = load.amountIn;
                    } else if (load.amountOut) {
                        estimatedBu = load.amountOut;
                    }
                    let newLoad = {
                        key: load.id!,
                        loadTime: load.dateTime ? dayjs(load.dateTime).format("MM/DD/YYYY h:mm a") : "",
                        eventType: eventType,
                        estimatedBu: estimatedBu,
                        srcOrDest: load.source ?? load.destination,
                        MC: load.loadMCPercent
                    };
                    loadsAndUnloads.push(newLoad);
                });

                setState({ loads: loadsAndUnloads, forcedNewBatch: false });
            } else {
                setState({ newBatchCheck: true, forcedNewBatch: true }); // should only go to here if there
            }
            switch (bin?.operatingMode) {
                case 0:
                    setState({ currentStep: CurrentStep.Idle });
                    break;
                case 1:
                    setState({ currentStep: CurrentStep.Fill });
                    break;
                case 2:
                    setState({ currentStep: CurrentStep.Dry });
                    break;
                case 3:
                    setState({ currentStep: CurrentStep.Dry });
                    break;
                // TopDry
                case 31:
                    setState({ currentStep: CurrentStep.Dry });
                    break;
                case 101:
                    setState({ currentStep: CurrentStep.SelfCheck });
                    break;
                case 4:
                    setState({ currentStep: CurrentStep.Storage });
                    break;
                case 5:
                    setState({ currentStep: CurrentStep.Unload });
                    break;
                case 100:
                    setState({ currentStep: CurrentStep.FailSafe });
                    break;
                case 20:
                    setState({ currentStep: CurrentStep.Manual });
                    break;
                default:
                    setState({ currentStep: CurrentStep.Unknown });
                    break;
            }
        },
        [state]
    );

    const fetchBinFromAzure = useCallback(
        async (deviceId: string, options = { delay: 0 }) => {
            if (options?.delay) {
                console.debug(`delaying fetchBin for ${options.delay} ms.`);
                await delay(options.delay);
            }
            const { binInfo } = state;
            const userTimeZoneOffset = getUserTimezoneOffset();
            setState({ fetchingBinFromDevice: true });
            try {
                const bin = await BinApiService.getBinDetailFromAzure(deviceId, binInfo.id, userTimeZoneOffset);

                if (
                    state?.bin?.captureTimeUtc != null &&
                    bin?.captureTimeUtc != null &&
                    // Bin state has been updated already, this is a stale response.
                    state?.bin?.captureTimeUtc > bin?.captureTimeUtc
                ) {
                    console.log("skipping updating binState due to returned binState detected as stale");
                    return setState({ fetchingBinFromDevice: false });
                } else {
                    setState({ bin, fetchingBinFromDevice: false });
                }
            } catch (error) {
                console.log(error);
                setState({ fetchingBinFromDevice: false });
            }
        },
        [state, delay, getUserTimezoneOffset]
    );

    const updateBin = useCallback(
        (bin: BinDTO) => {
            const topStackInfo =
                bin.stacks?.map(stack => {
                    return {
                        stackID: stack.id!,
                        topRH: stack.topRH,
                        topDP: stack.topDewPoint,
                        topTemp: stack.topTemp,
                        topAH: stack.topAbsoluteHumidity,
                        topMCPercent: stack.topMoistureContentPercent
                    };
                }) ?? [];
            console.log(`TopstackData: `, topStackInfo);

            let topStackAvgInfo = [];
            if (bin?.topInfo) {
                const topInfo = bin?.topInfo;
                const topStackAvgs: TopTableAvgData = {
                    topTemp: topInfo.avgTempF,
                    topAH: topInfo.avgAH,
                    topRH: topInfo.avgRH,
                    topDP: topInfo.avgDP,
                    topMCPercent: topInfo.avgMC
                };
                topStackAvgInfo.push(topStackAvgs);
            }

            let layersAvgInfo: LayerAvgTableData[] = [];
            if (bin?.layerAverages?.layers != null) {
                layersAvgInfo = [...(bin?.layerGrainStates ?? [])].reverse().map(layer => {

                    const preferredTemperatureSensorType = bin?.temperatureSensorsType ?? getDefaultTemperatureSensorType(bin);


                    let temperatureF = [TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast, TemperatureSensorEnum.BinSense].includes(preferredTemperatureSensorType) ? layer.temperatureF : layer.thermocoupleTemperatureF;
                    const layerAvgRow: LayerAvgTableData = {
                        layerNumber: layer.number,
                        tempF: temperatureF,
                        RH: layer.relitiveHumidity,
                        MCPercent: layer.moistureContent,
                        //AH: layer.ah,
                        dewPointF: layer.dewPointF
                    };
                    return layerAvgRow;
                });
            }
            const refreshTime = dayjs(bin.captureTimeUtc);

            setState({
                bin: bin,
                refreshTime: refreshTime.isValid() ? refreshTime : null,
                topStackTableInfo: topStackInfo,
                topStackTableAvgInfo: topStackAvgInfo,
                layerAvgTableInfo: layersAvgInfo,
                miscBinInfoTableData: [
                    {
                        Name: "Ambient",
                        Temperature: bin.ambientAir!.temp,
                        RelativeHumidity: bin.ambientAir!.rh,
                        EMC: bin.ambientAir!.mc,
                        dewPoint: bin?.ambientAir?.dp
                    },
                    {
                        Name: "Plenum",
                        Temperature: bin.plenumAir!.temp,
                        RelativeHumidity: bin.plenumAir!.rh,
                        EMC: bin.plenumAir!.mc,
                        dewPoint: bin?.plenumAir?.dp
                    }
                ]
            });
            updateCurrentStep(bin);
        },
        [updateCurrentStep]
    );

    const fetchBin = useCallback(
        async (deviceId: string, options = { delay: 0 }) => {
            if (options?.delay) {
                console.debug(`delaying fetchBin for ${options.delay} ms.`);
                await delay(options.delay);
            }
            const { binInfo } = state;
            const userTimeZoneOffset = getUserTimezoneOffset();
            setState({ fetchingBinFromDevice: true });
            try {
                const bin = await BinApiService.getBinDetailFromDevice(deviceId, binInfo.id, userTimeZoneOffset);

                if (
                    state?.bin?.captureTimeUtc != null &&
                    bin?.captureTimeUtc != null &&
                    // Bin state has been updated already, this is a stale response.
                    state?.bin?.captureTimeUtc > bin?.captureTimeUtc
                ) {
                    console.log("skipping updating binState due to returned binState detected as stale");
                    return setState({ fetchingBinFromDevice: false });
                } else {
                    setState({ bin, fetchingBinFromDevice: false });
                    updateBin(bin);
                }
            } catch (error) {
                console.log(error);
                setState({ fetchingBinFromDevice: false });
            }
        },
        [state, delay, getUserTimezoneOffset, updateBin]
    );

    const updateErrorList = (errorList: MappingErrorList) => {
        setState({ errorList: errorList });
        const activeErrorsReceived = activeErrors(errorList);
        setState({ activeErrors: activeErrorsReceived });
    };

    const fetchLatestErrorsFromDevice = useCallback(async () => {
        try {
            const errorList = await BinApiService.getLatestErrorsFromDevice(state.binInfo.deviceId!);
            console.debug("got errorList from device successfully", errorList);

            // todo future: The API field will never be null, limitation due to c# 7.3 in the backend. Remove this ! when updated to C# 8+
            updateErrorList(errorList.errorsList!);
        } catch (err) {
            console.warn("could not get errorList from device ", err);
            message.error("Failed to refresh alert monitor");
        }
    }, [state.binInfo.deviceId, updateErrorList]);

    const refreshBinAndErrors = useCallback(
        (event: any) => {
            event?.stopPropagation?.();
            fetchBin(state.binInfo?.deviceId!);
        },
        [fetchBin, state.binInfo?.deviceId]
    );

    const activeErrors = (errors: MappingErrorList): MappingErrorList => {
        if (errors == null) {
            return {};
        }
        const activeErrors: MappingErrorList = {};
        for (const err of Object.values(errors)) {
            if (err.isActive) {
                activeErrors[err.id] = err;
            }
        }
        return activeErrors;
    };

    React.useEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);

    const startSignalRConnection = useCallback(async (connection: HubConnection) => {
        try {
            await connection.start();
            console.log("signalR connected, ", "url: ", connection.baseUrl);
        } catch (err) {
            console.error("Error establishing signalr connection: ", err);
            setTimeout(() => startSignalRConnection(connection), 5000);
        }
    }, []);

    useEffect(() => {
        const binId = props.binInfo?.id;
        if (binId == null) {
            return;
        }
        let connection: HubConnection;
        let disconnectedDueToUnmount = false;

        const setUpSignalRConnection = async (binId: number) => {
            // Copied from here: https://subscription.packtpub.com/book/web-development/9781789950229/12/ch12lvl1sec93/interacting-with-the-signalr-real-time-api-from-react

            // TODO - setup connection to real-time SignalR API
            const connection = new HubConnectionBuilder()
                .withUrl("/signalr")
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();
            // TODO - handle Message function being called
            connection.on("NewSnapshot", async (message: any) => {
                console.log("signalr: got NewSnapshot message:", message);
                //fetchBinFromAzure(props.binInfo?.deviceId!);
                const bin = await binQueryAzure.refetch();
                if (bin.data != null) {
                    updateBin(bin.data);
                }
                //queryClient.invalidateQueries(deviceQueryKeys.stateFromAzure(props.binInfo?.deviceId!));
            });
            // start the connection
            await startSignalRConnection(connection);
            // subscribe to bin
            console.log("signalR: do we ever get here?");
            if (connection.state === HubConnectionState.Connected) {
                try {
                    await connection.invoke("SubscribeBin", binId);
                    console.log("signalr: subscribed to binId: ", props.binInfo?.id);
                } catch (err) {
                    console.error(`signalr: Error subscribing to bin with Id ${binId}`, err.toString());
                }
            } else {
                console.log("hub not connected");
            }

            // handle disconnect
            connection.onclose(async error => {
                console.log("disconnected from SignalR", error);
                if (disconnectedDueToUnmount) {
                    console.debug("skipping reconnect due to component cleanup/umount");
                    return;
                }
                await startSignalRConnection(connection);
            });

            //return the connection
            return connection;
        };

        if (binId) {
            setUpSignalRConnection(binId).then(con => {
                connection = con;
            });
        }

        const cleanUpSignalRConnection = async (binId: number, connection: HubConnection) => {
            if (connection.state === HubConnectionState.Connected) {
                connection;
                try {
                    await connection.invoke("UnsubscribeBin", binId);
                    console.log("unsubscribing from bin", binId);
                } catch (err) {
                    return console.error("Error unsubscribing to bin", binId, err.toString());
                }
                connection.off("SubscribeBin");
                disconnectedDueToUnmount = true;
                connection.stop();
            } else {
                connection.off("SubscribeBin");
                disconnectedDueToUnmount = true;
                connection.stop();
            }
        };

        return function cleanUp() {
            if (props.binInfo?.id) {
                const binId = props.binInfo?.id;
                cleanUpSignalRConnection(binId, connection).catch(err => console.log("failed to cleanup signalr", err));
            }
        };
    }, [props.binInfo?.id]);

    const moistureGraphQuery = useQuery(
        [...deviceQueryKeys.device(props.binInfo?.deviceId!), "mcGraph"],
        async q => {
            try {
                return await BinApiService.getMoistureContentSnapshotsOfLatestBatch(props.binInfo?.deviceId!, q.signal);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        {
            refetchInterval: 900 * 1_000,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            retry: 0,
            //enabled: isAdmin,
            enabled: false,
            onSuccess(data) {
                getAverageTemps(data);
            }
        }
    );

    const renderRoutineInfo = (pauseMessage: string) => {
        const automationType = selectAutomationType(state.bin);
        if (automationType === AutomationType.DriStack) {
            if (state.bin?.desiredProperties?.isSeedBin) {
                return `${state.bin?.currentRoutineName ?? "None"}` + `${state.bin?.routineEngine?.stepLabel ?? "None"}` + pauseMessage;
            } else {
                if (state.bin?.routineEngine?.routine == null) {
                    return "None";
                }
                return `${state.bin?.routineEngine?.routine ?? "None"}` + `: ${state.bin?.routineEngine?.stepLabel ?? "None"}` + pauseMessage;
            }
        } else {
            return `${pauseMessage}`;
        }
    };

    // componentDidMount() {
    //     this.divRef.current?.scrollIntoView();
    //     // fetchBin(state.binInfo.deviceId!);

    //     this.triggerUpdateData();
    //     this.interval = setInterval(this.triggerUpdateData, 900000);
    //     this.fetchLatestErrors().then(errorList => {
    //         console.debug('received error list: ', errorList);
    //         this.updateErrorList(errorList);
    //     }).catch(err => {
    //         console.error('error when fetching error list: ', err);
    //         message.error('Failed to retrieve monitored alarms');
    //     });

    // }

    // componentDidUpdate(_: Readonly<binStatsPageProps>, prevState: Readonly<State>, snapshot?: any): void {
    //     if (state.bin != null && prevState.bin !== state.bin) {
    //         console.log("updating outside binState", state.bin);
    //         this.updateBin(state.bin);
    //     }
    // }

    const modeWaitMessage = (meessageContent?: string) => {
        let toShow = "Applying mode change, site will update automatically in about 15 seconds";
        if (meessageContent != null) {
            toShow = meessageContent;
        }
        message.info(toShow);
    };

    const triggerUpdateData = useCallback(() => {
        const { binInfo } = state;
        if (binInfo == null) {
            // tab closed or first visit to this page. Go to homepage to populate history with needed state info
            console.log("no binInfo data, rerouting to /");
            props.history.push("/");
            return;
        }

        BinApiService.getMoistureContentSnapshotsOfLatestBatch(binInfo.deviceId!)
            .then(snapshots => {
                setState({ moistureSnapshotData: snapshots });
            })
            .catch(error => {
                console.log(error);
            });
    }, [state]);

    const populatefillFormInitialValues = (): FillFormValues => {
        // const targetMC = fillValues?.targetMC ?? state.bin?.currentBatch?.targetMC ?? 15.5;
        const grainType = fillValues?.grainType ?? state.bin?.currentBatch?.grainType ?? "Corn";

        const defaultEmcMin = 0;
        const defaultEmcMax = 17.5;
        const defaultTempMin = 32;
        const defaultTempMax = 105;

        // const heaterMode = fillValues?.heaterMode ?? state.bin?.currentBatch?.heaterMode ?? HeaterMode.EnergyEff;
        const fillInitialValues: FillFormValues = {
            grainType: grainType,
            emcMin: state.bin?.weatherMonitorState?.minMcLimit ?? defaultEmcMin,
            emcMax: state.bin?.weatherMonitorState?.maxMcLimit ?? defaultEmcMax,
            tempMin: state.bin?.weatherMonitorState?.minTemperatureLimitF ?? defaultTempMin,
            tempMax: state?.bin?.weatherMonitorState?.maxTemperatureLimitF ?? defaultTempMax,
            newBatch: false
        };
        //console.debug("fill initial values: ", fillInitialValues);
        return fillInitialValues;
    };

    const populateAddLoadFormInitialValues = (): AddLoadFormValues => {
        return {
            estimatedBu: 100,
            initialMC: 21,
            loadTime: dayjs(),
            source: ""
        };
    };

    const populateDryFormInitialValues = (): DryFormValues => {
        const grainType = state.bin?.grain?.grainType ?? GrainType.Corn;

        const numberOfLayers = state.bin?.layersAir?.length ?? 0;
        // equal split time per layer by default
        const defaultLayerPercentage = round((1 / numberOfLayers) * 100.0, 1);

        const layers: LayerAdjustment[] = [];
        for (let layer of state.bin?.layersAir ?? []) {
            layers.push({
                percentage: state.bin?.routineEngine?.layersPercentages?.[layer.number - 1]?.percentage ?? defaultLayerPercentage,
                number: layer.number
            });
        }

        let defaultEmcMin = 8;
        let defaultEmcMax = 17.5;
        let defaultTemperatureMinF = 40;
        let defaultTemperatureMaxF = 105;
        let defaultMaxGrainTemperatureF = 75;
        const dryFormInitialValues: DryFormValues = {
            grainType: grainType,
            heaterMode: NewHeaterMode.AUTO,
            emcMin: state.bin?.weatherMonitorState?.minMcLimit ?? defaultEmcMin,
            emcMax: state.bin?.weatherMonitorState?.maxMcLimit ?? defaultEmcMax,
            tempMin: state.bin?.weatherMonitorState?.minTemperatureLimitF ?? defaultTemperatureMinF,
            tempMax: state?.bin?.weatherMonitorState?.maxTemperatureLimitF ?? defaultTemperatureMaxF,
            maxLayerGrainTemperature: state.bin?.fanOperations?.maxAllowedLayerTemperature ?? defaultMaxGrainTemperatureF,
            layers: layers,
            heaterPlenumMcOffset: state.bin?.weatherMonitorState?.heaterPlenumMcOffset ?? 5
        };
        return dryFormInitialValues;
    };

    // reset dry form
    useEffect(() => {
        if (startDryForm.isFieldsTouched()) {
            return;
        }
        const dryFormInitialVals = populateDryFormInitialValues();

        startDryForm.setFieldsValue(dryFormInitialVals);
    }, [state.bin, startDryForm]);

    const populateStorageFormInitialValues = (): StorageFormValues => {
        const defaultEmcMin = 14.5;
        const defaultEmcMax = 16.5;
        const defaultTemperatureMinF = 32;
        const defaultTemperatureMaxF = 45;
        const defaultFanTimerWeeklyHours = 2;

        return {
            emcMin: state.bin?.weatherMonitorState?.minMcLimit ?? defaultEmcMin,
            emcMax: state.bin?.weatherMonitorState?.maxMcLimit ?? defaultEmcMax,
            tempMin: state.bin?.weatherMonitorState?.minTemperatureLimitF ?? defaultTemperatureMinF,
            tempMax: state?.bin?.weatherMonitorState?.maxTemperatureLimitF ?? defaultTemperatureMaxF,
            fanTimerWeeklyHours: defaultFanTimerWeeklyHours
        };
    };

    const populateStartManualFormValues = (): EnterManualFormValues => {
        const fanTimerSeconds = state.bin?.fanOperations?.fanRemainingOnTimeSeconds;

        return {
            fanTimerHours: round((fanTimerSeconds ?? 0) / 3600, 2)
        };
    };

    // componentWillUnmount() {
    //     clearInterval(this.interval);
    // }

    const setLoading = useCallback((val: boolean) => {
        setState({ loading: val });
    }, []);

    const changeGrainTypeHandleSubmit = async (vals: ChangeGrainTypeFormValues) => {
        const grainType = vals.grainType;
        setFillValues({ grainType: grainType });
        try {
            const result = await BinApiService.setGrainType(props.binInfo?.deviceId!, { grainType: grainType });
            if (result.success) {
                modeWaitMessage();
                toggleChangeGrainTypeModal(false);
                //changeGrainTypeFormMethods.resetFields();
            } else {
                message.error("System did not change grain type", 3);
            }
        } catch (error) {
            console.log("error changing grain type", error);
            message.error("Problem encountered changing Grain Type", 3);
        } finally {
            // not called since the module should update
            //fetchBin(props.binInfo?.deviceId!);
        }
    };

    const startFillMode = useCallback(
        (vals: FillFormValues) => {
            const { binInfo } = state;
            const grainType = vals.grainType ?? state.bin?.currentBatch?.grainType ?? "Corn";
            setFillValues({ grainType: grainType });
            setState({ buttonLoadingFeedBack: "Fill", addFillVisible: false });
            BinApiService.fill(
                {
                    newBatch: state.newBatchCheck,
                    grainType: vals.grainType ? vals.grainType : "Corn",
                    targetMC: 0,
                    weatherConditionsMinMc: vals.emcMin,
                    weatherConditionsMaxMc: vals.emcMax,
                    weatherConditionsMinTemperatureF: vals.tempMin,
                    weatherConditionsMaxTemperatureF: vals.tempMax
                },
                binInfo.deviceId!,
                binInfo.id
            )
                .then(res => {
                    var newBatch = state.newBatchCheck;
                    if (res) {
                        setState({
                            //currentStep: 1,
                            buttonLoadingFeedBack: "Done",
                            fillStart: false,
                            newBatchCheck: false,
                            forcedNewBatch: false,
                            loads: newBatch ? [] : state.loads
                        });
                        modeWaitMessage();
                    } else {
                        console.log(res);
                        setState({ buttonLoadingFeedBack: "Done", newBatchCheck: false });
                    }
                    fetchBin(props.binInfo?.deviceId!, { delay: 1 });
                })
                .catch(err => {
                    console.log(err);
                    message.error("Failed to enter Fill Mode", 3);
                    setState({ buttonLoadingFeedBack: "Done", newBatchCheck: false });
                });
        },
        [state, startFillForm, fetchBin, props.binInfo?.deviceId]
    );

    const setAddUnloadVisible = () => {
        setState({ addUnloadVisible: true });
    };

    const setAddUnloadNotVisible = useCallback(() => {
        setState({ addUnloadVisible: false });
    }, []);

    const handleSubmitUnloadForm = useCallback(
        (vals: UnloadFormSubmitValues) => {
            const { loads, bin, binInfo } = state;
            console.log(vals);
            const unload: Load = {
                key: state.loads.length + 1,
                eventType: "AddUnload",
                estimatedBu: vals.estimatedBu,
                loadTime: vals.unloadTime ? vals.unloadTime.format("MM/DD/YYYY h:mm a") : "",
                MC: vals.finalMC,
                srcOrDest: vals.destination
            };

            // Prevent duplicate unloads from being submitted with many button clicks
            setState({ pendingAddUnload: true });

            BinApiService.addUnload(
                {
                    id: null,
                    binID: binInfo.id,
                    dateTime: vals.unloadTime.toISOString(),
                    eventType: "AddUnload",
                    amountOut: vals.estimatedBu,
                    finalMC: vals.finalMC,
                    destination: vals.destination,
                    grainType: bin?.currentBatch?.grainType!,
                    batchId: bin?.currentBatch?.batchID!,
                    deviceName: binInfo.deviceId
                },
                binInfo.deviceId!,
                binInfo.id
            )
                .then(res => {
                    if (res !== 0) {
                        unload.key = res;
                        setState({ addUnloadVisible: false, loads: [...loads, unload] });
                    } else {
                        message.error("Error saving Load Out from The Current Batch, device rejected", 10);
                        //this.setAddUnloadNotVisible();
                    }
                    setState({ pendingAddUnload: false });
                })
                .catch(err => {
                    console.log(err);
                    message.error("__Error Saving Load Out from The Current Batch", 10);
                    //this.setAddUnloadNotVisible();
                    setState({ pendingAddUnload: false });
                });
        },
        [state]
    );

    const addLoadToTable = useCallback(
        async (vals: AddLoadFormValues) => {
            const { loads, bin, binInfo } = state;
            console.log("Load vals: ", vals);
            const load = {
                key: state.loads.length + 1,
                eventType: "Add Load",
                estimatedBu: vals.estimatedBu,
                loadTime: vals.loadTime ? vals.loadTime.format("MM/DD/YYYY h:mm a") : "",
                srcOrDest: vals.source,
                // height: vals.height,
                //targetMC: vals.targetMC,
                targetMC: 0,
                MC: vals.initialMC
            };

            // Prevent duplicate loads from being submitted with many button clicks
            setState({ pendingAddFill: true });

            BinApiService.addLoad(
                {
                    id: null,
                    amountOut: null,
                    binID: binInfo.id,
                    dateTime: vals.loadTime.toISOString(),
                    eventType: "AddLoad",
                    amountIn: vals.estimatedBu,
                    targetMC: bin?.currentBatch?.targetMC!,
                    initialMC: vals.initialMC,
                    source: vals.source,
                    destination: "",
                    batchEnd: false,
                    grainType: bin?.currentBatch?.grainType!,
                    batchId: bin?.currentBatch?.batchID!,
                    deviceName: binInfo.deviceId,
                    batchNumber: bin?.currentBatch?.batchNumber!,
                    loadMCPercent: vals.initialMC,
                    shouldDelete: false
                },
                binInfo.deviceId!,
                binInfo.id,
                bin?.currentBatch?.batchNumber!
            )
                .then(res => {
                    if (res !== 0) {
                        load.key = res;
                        setState({ addFillVisible: false, addLoadVisible: false, loads: [...loads, load] });
                    } else {
                        message.error("Error Adding Load To The Current Batch", 10);
                        setState({ addFillVisible: false });
                    }
                    setState({ pendingAddFill: false });
                })
                .catch(err => {
                    console.log(err);
                    message.error("Error Adding Load To The Current Batch", 10);
                    setState({ addFillVisible: false });
                    setState({ pendingAddFill: false });
                });
        },
        [state, addLoadForm]
    );

    const markLoadasPendingDelete = useCallback(
        (loadKey: number) => {
            const existingLoads = state.pendingDeleteLoad;
            const pendingDeleteKey = { [loadKey]: {} };
            const newState = { pendingDeleteLoad: { ...existingLoads, ...pendingDeleteKey } };
            setState(newState);
        },
        [state]
    );

    const markUnloadasPendingDelete = useCallback(
        (unloadKey: number) => {
            const existingUnloads = state.pendingDeleteUnload;
            const pendingDeleteKey = { [unloadKey]: {} };
            const newState = { pendingDeleteUnload: { ...existingUnloads, ...pendingDeleteKey } };
            setState(newState);
        },
        [state]
    );

    const deletedLoadState = useCallback(
        (loadKey: number) => {
            const existingLoads = state.pendingDeleteLoad;
            const { [loadKey]: remove, ...keep } = existingLoads;
            setState({ pendingDeleteLoad: keep });
        },
        [state]
    );

    const deletedUnloadState = useCallback(
        (unloadKey: number) => {
            const existingUnloads = state.pendingDeleteUnload;
            const { [unloadKey]: remove, ...keep } = existingUnloads;
            setState({ pendingDeleteUnload: keep });
        },
        [state]
    );

    const deleteLoad = useCallback(
        (record: Load) => {
            const { loads, binInfo, bin } = state;
            console.log(record.key);
            markLoadasPendingDelete(record.key);
            BinApiService.deleteLoad(record.key, binInfo.id, bin?.currentBatch?.batchNumber!)
                .then(res => {
                    if (res) {
                        const newTable = loads.filter((value, index, arr) => {
                            return value.key !== record.key;
                        });
                        setState({ loads: newTable });
                        deletedLoadState(record.key);
                    } else {
                        message.error("Error Trying To Delete Load");
                        deletedLoadState(record.key);
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Error Trying To Delete Load");
                    deletedLoadState(record.key);
                });
        },
        [state, markLoadasPendingDelete, deletedLoadState]
    );

    const deleteUnload = useCallback(
        (record: UnloadRow) => {
            const { loads, binInfo, bin } = state;
            console.log(record.key);
            markUnloadasPendingDelete(record.key);
            BinApiService.deleteUnload(record.key, binInfo.id, bin?.currentBatch?.batchNumber!)
                .then(res => {
                    if (res) {
                        const newTable = loads.filter((value, index, arr) => {
                            return value.key !== record.key;
                        });
                        setState({ loads: newTable });
                        deletedUnloadState(record.key);
                    } else {
                        message.error("Error Trying To Delete Unload");
                        deletedUnloadState(record.key);
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Error Trying To Delete UnlLoad");
                    deletedUnloadState(record.key);
                });
        },
        [state, markUnloadasPendingDelete, deletedUnloadState]
    );

    const emptyBinSubmit = () => {
        // TODO
    };

    const startDrying = useCallback(
        (vals: DryFormValues) => {
            const { binInfo } = state;
            const grainType = vals.grainType ?? state.bin?.currentBatch?.grainType ?? "Corn";
            // depreciated - uses emc/temp min&max now
            //const targetMC = vals.targetMC || (state.bin?.currentBatch?.targetMC ?? null);
            const targetMC = 0;

            let heaterMode = null;
            if (vals.heaterMode === NewHeaterMode.AUTO) {
                heaterMode = null;
            } else if (vals.heaterMode === NewHeaterMode.OFF) {
                heaterMode = false;
            } else if (vals.heaterMode === NewHeaterMode.ON) {
                heaterMode = true;
            }

            const emcMin = vals.emcMin;
            const emcMax = vals.emcMax;
            const tempMin = vals.tempMin;
            const tempMax = vals.tempMax;
            const maxLayerGrainTemperature = vals.maxLayerGrainTemperature;

            // layers
            const layers = vals.layers;

            setFillValues({
                grainType: grainType,
                targetMC: targetMC
                //heaterMode: heaterMode,
            });
            setState({ buttonLoadingFeedBack: "Dry", endFillVisible: false });

            BinApiService.dry(
                {
                    loads: state.loads,
                    grainType: vals.grainType,
                    // deprecated
                    targetMC: targetMC,
                    heaterMode: heaterMode,
                    weatherConditionsMinMc: emcMin,
                    weatherConditionsMaxMc: emcMax,
                    weatherConditionsMinTemperatureF: tempMin,
                    weatherConditionsMaxTemperatureF: tempMax,
                    maxLayerGrainTemperature: maxLayerGrainTemperature,
                    layersTimePercentages: layers,
                    heaterPlenumMcOffset: vals.heaterPlenumMcOffset
                },
                binInfo.deviceId!,
                binInfo.id!
            )
                .then(ret => {
                    if (ret) {
                        setState({ buttonLoadingFeedBack: "Done" });
                        fetchBin(props.binInfo?.deviceId!);
                        modeWaitMessage();
                    } else {
                        console.log(ret);
                        setState({ buttonLoadingFeedBack: "Done" });
                    }
                })
                .catch(err => {
                    setState({ buttonLoadingFeedBack: "Done" });
                    message.error("Failed to enter Dry mode");
                    console.log(err);
                });
        },
        [state]
    );

    const backToDry = useCallback(() => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "Dry", endFillVisible: false });
        BinApiService.dry({}, binInfo.deviceId!, binInfo.id!)
            .then(ret => {
                if (ret) {
                    setState({ buttonLoadingFeedBack: "Done" });
                    modeWaitMessage();
                } else {
                    console.log(ret);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
                fetchBin(props.binInfo?.deviceId!);
            })
            .catch(err => {
                setState({ buttonLoadingFeedBack: "Done" });
                console.log(err);
            });
    }, [state, fetchBin]);

    const startManualDry = () => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "StartManualDry" });

        BinApiService.startManual({}, binInfo.deviceId!)
            .then(res => {
                fetchBin(binInfo.deviceId!);
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done" });
                } else {
                    message.error("Failed to enter User Control mode");

                    setState({ buttonLoadingFeedBack: "Done" });
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to enter User Control mode");
                setState({ buttonLoadingFeedBack: "Done" });
            });
    };

    const stopManualDry = () => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "StopManualDry" });

        BinApiService.stopManual(binInfo.deviceId!)
            .then(res => {
                fetchBin(binInfo.deviceId!);

                // TODO may need to be changed
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done" });
                } else {
                    message.error("Failed to exit User Control mode");
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Failed exitting User Control mode");
                setState({ buttonLoadingFeedBack: "Done" });
            });
    };

    const pause = useCallback(async () => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "Pause" });
        try {
            const res = await BinApiService.pause({}, binInfo.deviceId!);
            if (res) {
                setState({ buttonLoadingFeedBack: "Done" });
                modeWaitMessage();
            } else {
                console.log(res);
                setState({ buttonLoadingFeedBack: "Done" });
            }
        } catch (err) {
            console.log(err);
            setState({ buttonLoadingFeedBack: "Done" });
            message.error("Failed to Pause operations", 3);
        }
        fetchBin(state.binInfo.deviceId!);
    }, [state, fetchBin]);

    const resume = useCallback(async () => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "Resume" });
        try {
            const res = await BinApiService.resume({}, binInfo.deviceId!);
            if (res) {
                setState({ buttonLoadingFeedBack: "Done" });
                modeWaitMessage();
            } else {
                console.log(res);
                setState({ buttonLoadingFeedBack: "Done" });
            }
        } catch (err) {
            console.log(err);
            message.error("Failed to Resume operations");
            setState({ buttonLoadingFeedBack: "Done" });
        }
        fetchBin(state.binInfo.deviceId!);
    }, [state, fetchBin]);

    const returnToIdle = useCallback(() => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "Idle" });
        BinApiService.idle(binInfo.deviceId!)
            .then(res => {
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done" });
                    fetchBin(props.binInfo?.deviceId!);
                    modeWaitMessage();
                } else {
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to enter Idle mode");
                setState({ buttonLoadingFeedBack: "Done" });
            });
    }, [state, fetchBin, props.binInfo?.deviceId]);

    // const openAlertsHistory = useCallback(() => {
    //     setState({showAlertsHistoryTable: true})
    // }, []);

    // const closeAlertsHistory = useCallback(() => {
    //     setState({showAlertsHistoryTable: false})
    // }, []);

    const openStorageForm = useCallback(() => {
        storageForm.setFieldsValue(populateStorageFormInitialValues());
        setState({ showStorageDialog: true });
        storageForm.setFieldsValue(populateStorageFormInitialValues());
    }, [state, setState, populateStorageFormInitialValues]);

    const closeStorageForm = useCallback(() => {
        setState({ showStorageDialog: false });
    }, []);

    const storage = useCallback(
        async (data: StorageFormValues) => {
            const { binInfo } = state;
            setState({ buttonLoadingFeedBack: "Storage" });
            try {
                const fanTimerWeeklySeconds = round(data.fanTimerWeeklyHours * 3600);
                const res = await BinApiService.storage(
                    {
                        weatherConditionsMinMc: data.emcMin,
                        weatherConditionsMaxMc: data.emcMax,
                        weatherConditionsMinTemperatureF: data.tempMin,
                        weatherConditionsMaxTemperatureF: data.tempMax,
                        fanOnTimePerWeekSeconds: fanTimerWeeklySeconds
                    },
                    binInfo.deviceId!
                );
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done" });
                    modeWaitMessage();
                    closeStorageForm();
                } else {
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
                fetchBin(props.binInfo?.deviceId!);
            } catch (error) {
                console.log("Failed to enter Storage mode", error);
                message.error("Failed to enter Storage mode");
                setState({ buttonLoadingFeedBack: "Done" });
            }
        },
        [state, props.binInfo?.deviceId, fetchBin, closeStorageForm]
    );

    const unload = useCallback(() => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "Unload" });
        BinApiService.unload({}, binInfo.deviceId!)
            .then(res => {
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done" });
                } else {
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
                fetchBin(props.binInfo?.deviceId!);
                modeWaitMessage();
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to enter Unload mode");
                setState({ buttonLoadingFeedBack: "Done" });
            });
    }, [state, props.binInfo?.deviceId!, fetchBin]);

    const startManual = useCallback(() => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "EnteringManualMode" });
        BinApiService.startManual({}, binInfo.deviceId!)
            .then(res => {
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done", showManualEnterModal: false, showManualFanSettingsModal: false });
                    //console.log("dialog should show for manual fan settings");
                    fetchBin(state.binInfo.deviceId!, { delay: 3 });
                    modeWaitMessage();
                } else {
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done", showManualFanSettingsModal: false });
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to enter User Control mode");
                setState({ buttonLoadingFeedBack: "Done", showManualFanSettingsModal: false });
            });
    }, [state, setState]);

    const startManualWithTimer = useCallback(
        (data: EnterManualFormValues) => {
            const { binInfo } = state;
            const fanRemainingOnTimeSeconds = round(data.fanTimerHours * 3600);
            setState({ buttonLoadingFeedBack: "EnteringManualMode" });
            BinApiService.startManual(
                {
                    fanRemainingOnTimeSeconds: fanRemainingOnTimeSeconds
                },
                binInfo.deviceId!
            )
                .then(res => {
                    if (res) {
                        //setState({ buttonLoadingFeedBack: 'Done', showManualEnterModal: false, showManualFanSettingsModal: true});
                        //console.log("dialog should show for manual fan settings");
                        fetchBin(state.binInfo.deviceId!, { delay: 3 });
                        modeWaitMessage();
                    } else {
                        console.log(res);
                        setState({ buttonLoadingFeedBack: "Done", showManualFanSettingsModal: false });
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Failed to enter User Control mode");
                    setState({ buttonLoadingFeedBack: "Done", showManualFanSettingsModal: false });
                });
        },
        [state, setState]
    );

    const exitManual = () => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "ExitingManualMode" });
        BinApiService.stopManual(binInfo.deviceId!)
            .then(res => {
                if (res) {
                    setState({ buttonLoadingFeedBack: "Done" });
                    fetchBin(state.binInfo.deviceId!);
                    modeWaitMessage();
                } else {
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to exit User Control mode");
                setState({ buttonLoadingFeedBack: "Done" });
            });
    };

    const exitFailSafe = useCallback(async () => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "ExitingFailSafe" });
        try {
            const res: boolean = await BinApiService.stopManual(binInfo.deviceId!);
            if (res) {
                fetchBin(state.binInfo?.deviceId!);
                setState({ buttonLoadingFeedBack: "Done" });
                modeWaitMessage();
            } else {
                fetchBin(state.binInfo?.deviceId!);
                console.log(res);
                message.error("Rejected exitting Fail Safe");
                setState({ buttonLoadingFeedBack: "Done" });
            }
        } catch (error) {
            console.log(error);
            fetchBin(state.binInfo?.deviceId!);
            message.error("Failed to exit Fail Safe mode");
            setState({ buttonLoadingFeedBack: "Done" });
        }
    }, [state, fetchBin]);

    const enterFillDialog = useCallback(() => {
        startFillForm.setFieldsValue(populatefillFormInitialValues());
        setState({ addFillVisible: true, fillStart: true });
        startFillForm.setFieldsValue(populatefillFormInitialValues());
    }, [setState, state]);

    const enterDryDialog = useCallback(() => {
        startDryForm.setFieldsValue(populateDryFormInitialValues());
        setState({ endFillVisible: true, addFillVisible: false, fillStart: false });
        startDryForm.setFieldsValue(populateDryFormInitialValues());
    }, [setState, state]);

    const returnToFill = useCallback(() => {
        const { binInfo } = state;
        setState({ buttonLoadingFeedBack: "retFill" });
        BinApiService.fill({ newBatch: false }, binInfo.deviceId!, binInfo.id!)
            .then(res => {
                if (res === true) {
                    setState({ buttonLoadingFeedBack: "Done" });
                    fetchBin(props.binInfo?.deviceId!);
                    modeWaitMessage();
                } else {
                    console.log(res);
                    setState({ buttonLoadingFeedBack: "Done" });
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to enter Fill mode");
                setState({ buttonLoadingFeedBack: "Done" });
            });
    }, [state, fetchBin, props.binInfo?.deviceId]);

    // onFinish = (values: any) => {
    //     const { binInfo } = state;
    //     let { targetMoisture } = values;
    //     let targetNum = toNumber(targetMoisture);
    //     if (targetNum >= state.highMoisture) {
    //         setState({ warningPopup: true });
    //     } else {
    //         EnterpriseApiService.changeBinTargetMoisture(binInfo.id, targetNum).then((bin) => {
    //             setState({ binInfo: bin });
    //         }).catch((error) => {
    //             console.log(error);
    //         });
    //     }
    // }

    const getPageHeaderButtons = useCallback(
        (isAdmin: boolean, automationType: AutomationType): JSX.Element => {
            console.log("state for page headers: ", state);

            const EnterManualButton = <Button onClick={() => startManual()}>Enter User Control Mode</Button>;

            const PauseButton = (
                <PopconfirmYesNo title="Pause Operations?" onConfirm={pause}>
                    <Button>
                        <PauseOutlined /> Pause
                    </Button>
                </PopconfirmYesNo>
            );

            const ResumeButton = (
                <PopconfirmYesNo title="Resume Operations?" onConfirm={resume}>
                    <Button>
                        <CaretRightFilled /> Resume
                    </Button>
                </PopconfirmYesNo>
            );

            const getPauseOrResumeButton = () => {
                if ([OperatingMode.Idle].includes(state.bin?.operatingMode!) && !state.bin?.isPaused) {
                    return null;
                }
                if (state.bin?.isPaused) {
                    return ResumeButton;
                } else {
                    return PauseButton;
                }
            };

            // Radio.Button. Couldn't get TS to recognize Radio.Button
            const buttons: Array<JSX.Element> = [];

            // buttons.push(<Radio.Button onClick={openAlertsHistory} className="mode-control-button">Alerts <br /> History</Radio.Button>)
            if (automationType === AutomationType.DriStack) {
                buttons.push(
                    <PopconfirmYesNo title="Enter Idle Mode?" onConfirm={returnToIdle}>
                        <Radio.Button className="mode-control-button" value={CurrentStep.Idle}>
                            Idle <br /> Mode
                        </Radio.Button>
                    </PopconfirmYesNo>
                );
                buttons.push(
                    <PopconfirmYesNo title="Enter User Control Mode?" onConfirm={startManual}>
                        <Radio.Button value={CurrentStep.Manual} className="mode-control-button">
                            User Control <br /> Mode
                        </Radio.Button>
                    </PopconfirmYesNo>
                );
                buttons.push(
                    <Radio.Button onClick={enterFillDialog} className="mode-control-button" value={CurrentStep.Fill}>
                        Fill & Aerate <br /> Mode
                    </Radio.Button>
                );
                buttons.push(
                    <Radio.Button onClick={enterDryDialog} value={CurrentStep.Dry} className="mode-control-button">
                        Dry <br /> Mode
                    </Radio.Button>
                );
                buttons.push(
                    <Radio.Button onClick={openStorageForm} value={CurrentStep.Storage} className="mode-control-button">
                        Storage <br /> Mode
                    </Radio.Button>
                );

                // buttons.push(
                //     <PopconfirmYesNo title="Enter Unload mode?" onConfirm={unload}>
                //         <Radio.Button value={CurrentStep.Unload} className="mode-control-button">Unload <br /> Mode</Radio.Button>
                //     </PopconfirmYesNo>);
            }

            if (automationType === AutomationType.DriStack) {
                return (
                    <>
                        {/* {state.currentStep === CurrentStep.Fill && <Button onClick={() => setState({ addLoadVisible: true })}><PlusOutlined />Add Load</Button>} */}
                        {/* {state.currentStep === CurrentStep.Unload && <Button onClick={setAddUnloadVisible}><PlusOutlined />Add Load Out</Button>} */}
                        {state.currentStep === CurrentStep.Manual && (
                            <PopconfirmYesNo
                                title="Exit User Control Mode?"
                                description="Returns to previous mode with that mode's settings"
                                onConfirm={exitManual}
                            >
                                <Button>Exit User Control Mode</Button>
                            </PopconfirmYesNo>
                        )}
                        {getPauseOrResumeButton()}
                        <Radio.Group size="small" value={state.currentStep} buttonStyle="solid">
                            {buttons}
                        </Radio.Group>
                    </>
                );
            } else {
                switch (state.currentStep) {
                    case 0: {
                        return <>{EnterManualButton}</>;
                    }
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 20: {
                        return <>{state.bin!.isPaused ? ResumeButton : null}</>;
                    }
                    default:
                        return <></>;
                }
            }
        },
        [state, backToDry, exitManual, pause, setAddUnloadVisible, startManualWithTimer, storage, resume, returnToFill, returnToIdle, unload]
    );

    const hasPendingLoadDeletes = useCallback((): boolean => {
        return Object.keys(state.pendingDeleteLoad).length > 0;
    }, [state]);

    const hasPendingUnloadDeletes = useCallback((): boolean => {
        return Object.keys(state.pendingDeleteUnload).length > 0;
    }, [state]);

    const closeFillOrAddLoadDialog = useCallback(() => {
        setState({ addFillVisible: false, fillStart: false, newBatchCheck: false, pendingAddFill: false, addLoadVisible: false });
    }, [setState]);

    const closeStartDryDialog = useCallback(() => {
        setState({ endFillVisible: false, newBatchCheck: false });
        // reset form values
        startDryForm.resetFields();
        startDryForm.resetFields();
    }, [startDryForm]);

    const closeAddUnload = useCallback(() => {
        setState({ addUnloadVisible: false });
    }, []);

    const formatEstiamtedCapacityBuThousand = (binDTO: BinDTO | undefined): string | null => {
        if (binDTO == null) {
            return "---";
        }
        const calculatedBu = calculateBinCapacity(binDTO);
        if (calculatedBu == null) {
            return "--";
        } else {
            const kBu = (calculatedBu / 1000).toFixed(2);
            return `${kBu} k bu.`;
        }
    };

    const formatEstiamtedBu = (binDTO: BinDTO | undefined): string | null => {
        if (binDTO == null) {
            return "---";
        }
        const calculatedBu = calculateEstimatedBu(binDTO);
        if (calculatedBu == null) {
            return "--";
        } else {
            const kBu = (calculatedBu / 1000).toFixed(2);
            return `${kBu} k bu.`;
        }
    };

    const calculateBinCapacity = (binDTO: BinDTO): number | null => {
        const calculatedBinCapacity = binDTO?.capacityBushels;
        if (calculatedBinCapacity == null) {
            return null;
        }

        return calculatedBinCapacity;
    };

    const calculateEstimatedBu = (binDTO: BinDTO): number | null => {
        const percentFull = binDTO?.grain?.percentFullCalculated;
        const calculatedBinCapacity = binDTO?.capacityBushels;

        if (binDTO.grain == null) {
            return null;
        }
        if (percentFull == null) {
            return null;
        }
        if (calculatedBinCapacity == null) {
            return null;
        }

        return calculatedBinCapacity * (percentFull / 100.0);
    };

    const turnonFans = () => {
        message.info(`Fans turning on. Please wait.`, 4);
        BinApiService.turnOnFans(props.binInfo.deviceId!)
            .then(succeeded => {
                if (succeeded) {
                } else {
                    message.destroy();
                    message.error(`One or more Fans did not turn on`);
                }
            })
            .catch(err => {
                message.destroy();
                message.error(`Failure Turning on Fans`);
            });
    };

    const turnOffFans = () => {
        message.info(`Fans turning off. Please wait.`, 4);
        BinApiService.turnOffFans(props.binInfo?.deviceId!)
            .then(succeeded => {
                if (succeeded) {
                } else {
                    message.destroy();
                    message.error(`One or more Fans did not turn off`);
                }
            })
            .catch(err => {
                message.destroy();
                message.error(`Failure Turning off Fans`);
            });
    };

    const turnOnFan = useCallback(
        (fanId: string, fanIndex: number) => {
            message.info(`Fan ${fanId} turning on. Please wait.`, 4);
            BinApiService.turnOnFan(fanId, props.binInfo?.deviceId!)
                .then(succeeded => {
                    if (succeeded) {
                    } else {
                        message.destroy();
                        message.error(`Fan ${fanId} did not turn on`);
                    }
                })
                .catch(err => {
                    message.destroy();
                    message.error(`Failed to turn on fan ${fanId}`);
                });
        },
        [state, props.binInfo?.deviceId!]
    );

    const turnOffFan = useCallback(
        (fanId: string, fanIndex: number) => {
            message.info(`Fan ${fanId} turning off. Please wait.`, 4);
            BinApiService.turnOffFan(fanId, props.binInfo?.deviceId!)
                .then(succeeded => {
                    if (succeeded) {
                    } else {
                        message.destroy();
                        message.error(`Fan ${fanId} did not turn off`);
                    }
                })
                .catch(err => {
                    message.destroy();
                    message.error(`Failed to turn off fan ${fanId} `);
                    console.error(`failed to turn off fan ${fanId}`, err);
                });
        },
        [state, props.binInfo?.deviceId!]
    );

    const turnOnCompressor = useCallback(() => {
        message.info("Requesting compressor to turn on. Please wait.", 4);
        BinApiService.turnOnCompressor(props.binInfo?.deviceId!)
            .then(successful => {
                if (successful) {
                    // true just means the action is attempting to be carried out.
                    message.success("Compressor requested to turn On.");
                } else {
                    message.destroy();
                    message.error("Compressor did not turn on");
                }
            })
            .catch(err => {
                message.destroy();
                message.error("Failed to turn on compressor");
                console.error("Failed to turn on compressor", err);
            });
    }, [props.binInfo?.deviceId!]);

    const turnOffCompressor = useCallback(() => {
        message.info("Requesting compressor to turn off. Please wait.", 4);
        BinApiService.turnOffCompressor(props.binInfo?.deviceId!)
            .then(successful => {
                if (successful) {
                    message.success("Requested compressor to turn Off.");
                } else {
                    message.destroy();
                    message.error("Compressor did not turn off");
                }
            })
            .catch(err => {
                message.error("Failed to turn off compressor");
                console.error("Failed to turn off compressor", err);
            });
    }, [props.binInfo?.deviceId!]);

    const turnOnHeater = useCallback(
        (fanId: string) => {
            message.info(`Requesting heater for Fan ${fanId} to turn on. Please wait.`, 4);
            BinApiService.turnOnHeater(props?.binInfo?.deviceId!, fanId)
                .then(succeeded => {
                    if (succeeded) {
                        message.success(`Heater for Fan ${fanId} requested to turn on.`);
                    } else {
                        message.destroy();
                        message.error(`Heater for Fan ${fanId} did not turn on`);
                    }
                })
                .catch(err => {
                    message.destroy();
                    message.error(`Failed to turn on heater for Fan ${fanId}`);
                });
        },
        [props.binInfo?.deviceId!]
    );

    const turnOnHeaters = useCallback(() => {
        message.info("Requesting heaters to turn on. Please wait.", 4);
        BinApiService.turnOnHeaters(props.binInfo?.deviceId!)
            .then(succeeded => {
                if (succeeded) {
                    message.success("Heaters requested to turn on.");
                } else {
                    message.destroy();
                    message.error("Heaters did not turn on");
                }
            })
            .catch(err => {
                message.destroy();
                message.error("Failed to turn on heaters");
            });
    }, [state, props.binInfo?.deviceId!]);

    const turnOffHeater = useCallback(
        (fanId: string) => {
            message.info(`Requesting heater for Fan ${fanId} to turn off. Please wait.`, 4);
            BinApiService.turnOffHeater(props.binInfo?.deviceId!, fanId)
                .then(succeeded => {
                    if (succeeded) {
                        message.success(`Heater for Fan ${fanId} requested to turn off.`);
                    } else {
                        message.destroy();
                        message.error(`Heater for Fan ${fanId} did not turn off`);
                    }
                })
                .catch(err => {
                    message.destroy();
                    message.error(`Failed to turn off heater for Fan ${fanId}`);
                });
        },
        [props.binInfo?.deviceId!]
    );

    const turnOffHeaters = useCallback(() => {
        message.info("Requesting heater to turn off. Please wait.", 4);
        BinApiService.turnOffHeaters(props.binInfo?.deviceId!)
            .then(succeeded => {
                if (succeeded) {
                    message.success("Heaters requested to turn off.");
                } else {
                    message.destroy();
                    message.error("Heaters did not turn off");
                }
            })
            .catch(err => {
                message.destroy();
                message.error("Failed to turn off heaters");
            });
    }, [props.binInfo?.deviceId!]);

    const onHeaterModeChangeModalOk = useCallback(
        (desiredHeaterMode: HeaterMode) => {
            setState({ isOpenHeaterChange: false });
            console.log("outside: desired heater mode: ", desiredHeaterMode);
            fetchBin(props.binInfo?.deviceId!, { delay: 2000 });
        },
        [fetchBin, props.binInfo?.deviceId]
    );

    const onHeaterModeChangeModalCancel = useCallback(() => {
        setState({ isOpenHeaterChange: false });
    }, []);

    const onChangeHeaterModeClick = useCallback(() => {
        setState({ isOpenHeaterChange: true });
    }, []);

    const handleFanSettingsSubmit = useCallback(
        async (data: SettingsFormValues): Promise<boolean> => {
            console.log("the form data to be sumbitted: ", data);

            let successful = false;
            try {
                if (
                    data.fanRemainingTimeOn != null &&
                    ![OperatingMode.FillBin, OperatingMode.Idle, OperatingMode.EmptyBin].includes(state.bin?.operatingMode!)
                ) {
                    const response1 = await BinApiService.setFanRemainingOnTime({
                        seconds: round(data.fanRemainingTimeOn * 3600),
                        deviceId: state.binInfo?.deviceId!
                    });
                    successful = true;
                    message.success("Fan run timer applied");
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    message.error(`Problem applying fan run timer: ${error?.message ?? ""}`);
                }
                successful = false;
            }

            try {
                const response = await BinApiService.updateSettings(
                    { mode: data.mode, minEMC: data.minEMC, maxEMC: data.maxEMC, minTemp: data.minTemp, maxTemp: data.maxTemp },
                    state.binInfo?.deviceId!
                );
                successful = true;
                message.success("Fan settings applied");
            } catch (error) {
                if (error instanceof ApiError) {
                    message.error(`Problem applying fan settings: ${error?.message ?? ""}`);
                } else {
                    console.error("unknown reason for failing to apply fan settings", error);
                }
                successful = false;
            } finally {
                // await queryClient.invalidateQueries(deviceQueryKeys.stateFromDevice(state.binInfo?.deviceId!));
                fetchBin(state.binInfo?.deviceId!, { delay: 3 * 1000 });
            }

            return successful;
        },
        [state, fetchBin, props.binInfo?.deviceId!]
    );

    const convertFanSettingsToFormSettings = useCallback(() => {
        const formSettings: Partial<ReportedFormFanSettings> = {};
        // manual settings
        const current = state.bin?.weatherMonitorState;
        if (current == null) {
            return formSettings;
        }
        formSettings.minEMC = current?.minMcLimit;
        formSettings.maxEMC = current.maxMcLimit;

        let convertedMode = ManualFanMode.AutoWeather;
        switch (state.bin?.fanOperations?.desiredFanOn) {
            case true: {
                convertedMode = ManualFanMode.AlwaysOn;
                if (state.bin?.fanOperations?.ignoreWeatherConditions === false) {
                    convertedMode = ManualFanMode.AutoWeather;
                }
                break;
            }
            case false: {
                convertedMode = ManualFanMode.AlwaysOff;
                break;
            }
            case null: {
                convertedMode = ManualFanMode.AutoWeather;
                break;
            }
            default: {
                convertedMode = ManualFanMode.AutoWeather;
                break;
            }
        }
        formSettings.mode = convertedMode;
        console.log("selected mode: ", formSettings);
        formSettings.minTemp = current.minTemperatureLimitF;
        formSettings.maxTemp = current.maxTemperatureLimitF;
        //formSettings.ts = state?.bin?.reportedProperties;
        formSettings.lastUpdated = bin?.captureTimeUtc?.toString();
        console.log("form settings reported last updated: ", formSettings.lastUpdated);
        return formSettings;
    }, [state]);

    const getAverageTemps = (moistureSnapshots: MoistureContentSnapshotDTO[]) => {
        // var binStackData = state.bin!.stacks;
        // var layerTemps: any[] = [];
        // var addedValues: number[] = [];
        // binStackData!.forEach((stack) => {
        //     var thermos = stack.thermocouples;
        //     thermos!.forEach((sensor) => {
        //         if (layerTemps[sensor.number - 1]) {
        //             var templist = layerTemps[sensor.number - 1];
        //             var tempAdded = addedValues[sensor.number - 1];
        //             tempAdded += sensor.temperature;
        //             templist.push(sensor.temperature);
        //             layerTemps[sensor.number - 1] = templist;
        //             addedValues[sensor.number - 1] = tempAdded;
        //         } else {
        //             layerTemps.push([sensor.temperature]);
        //             addedValues.push(sensor.temperature);
        //         }

        //     });
        // });
        // // console.log(layerTemps);
        // var i = 0;
        // layerTemps.forEach((layer: number[]) => {
        //     this.averTemps.push(addedValues[i++] / layer.length);
        // });

        fillInGraphAndTables(moistureSnapshots);
    };

    // useEffect(() => {
    //     changeGrainTypeFormMethods.resetFields();
    // }, [changeGrainTypeFormMethods, state.bin?.grain?.grainType])

    const fillInGraphAndTables = (moistureSnapshots: MoistureContentSnapshotDTO[]) => {
        // let levelData = state.dummyLevelData; // TODO replace with real Data
        // var j = 0;
        // if (levelData) {
        //     let levelDataValues: any = [];
        //     levelData!.forEach((level: BinHistoryDataDTO, i: number) => {
        //         var mc = level.currMoistureContent.toString() + '%';
        //         var Temp = this.averTemps[j].toFixed(1) + '°F';
        //         levelDataValues.push({
        //             key: i,
        //             level: level.name,
        //             mc: mc,
        //             t: Temp,
        //             mst: level.currMaxStorageTime,
        //         });
        //         j++;

        //         /*var data: { x: number; y: number; }[] = [];
        //         if (level.dataPoints) {
        //             level.dataPoints!.forEach((dataPoints: BinHistoryPointDTO) => {
        //                 data.push({
        //                     x: dataPoints.dataTime,
        //                     y: dataPoints.moistureContent
        //                 });
        //                 this.graphData.push({
        //                     key: i * -1,
        //                     name: level.name,
        //                     data: data,

        //                 });
        //             });
        //         }*/
        //     });
        //     setState({
        //         binStatsData: levelDataValues
        //     });
        // }
        let tempData: any = [];
        if (moistureSnapshots != null && moistureSnapshots?.length > 0) {
            moistureSnapshots.forEach((dataPoints: MoistureContentSnapshotDTO, index) => {
                let dataPointTime = dayjs(dataPoints.snapshotTime).toDate();
                tempData.push({
                    x: dataPointTime,
                    y: dataPoints.moistureContent
                });
            });

            setState({
                graphData: tempData,
                mcChartOffline: false
            });
        } else {
            console.debug("no chart data, got moistureSnapshotData: ", state?.moistureSnapshotData);
            setState({ mcChartOffline: true });
        }
    };

    let { binInfo, offline, buttonLoadingFeedBack, mcChartOffline } = state;
    const bin = state.bin;
    const inManualMode = bin?.operatingMode === OperatingMode.Manual;
    const automationType = selectAutomationType(bin);
    const hasFans = (state.bin?.fans?.length ?? 0) > 0;
    const operatingModeText = getCurrentModeDescription(bin);
    const showCurrentRoutine = [OperatingMode.Manual, OperatingMode.Dry, OperatingMode.PreDry, OperatingMode.TopDry, OperatingMode.FillBin].includes(
        state.bin?.operatingMode!
    );

    if (binInfo == null) {
        // tab closed or first visit to this page. Go to homepage to populate history with needed state info
        return (
            <Layout.Content className="dashboard">
                <Spin />;
            </Layout.Content>
        );
    }

    console.log("========== New Render/Refresh========");

    document.title = `Shivvers Tech - ${binInfo.name ?? "No device name"}`;

    const criticalNotifications: ErrorDTO[] = [];
    const warningNotifications: ErrorDTO[] = [];

    for (const err of Object.values(state.activeErrors)) {
        switch (err?.priority) {
            case ErrorPriority.CriticalError:
                criticalNotifications.push(err);
                break;
            case ErrorPriority.Warning:
                warningNotifications.push(err);
                break;
            default: {
                console.warn(`unknown err priority: ${err?.name}, priority: ${err?.priority}`);
                criticalNotifications.push(err!);
            }
        }
    }

    const renderedCrit = criticalNotifications.map((err, idx) => {
        return (
            <Collapse.Panel key={err.id} header={`Critical: ${err.name}`} className="criticalNotificationPanel">
                <span className="criticalNotificationSection">{`${err.message}`}</span>
            </Collapse.Panel>
        );
    });

    const renderedWarning = warningNotifications.map((err, idx) => {
        return (
            <Collapse.Panel key={err.id} header={`Warning: ${err.name}`} className="warningNotificationPanel">
                <span className="warningNotificationSection">{`${err.message}`}</span>
            </Collapse.Panel>
        );
    });

    // we don't control heaters on autobins this year (2023)
    // let hasHeater = !(state.bin?.desiredProperties?.overrides?.noHeater === true) || automationType === AutomationType.DriStack;
    let hasHeater = (state.bin?.hasHeaters);
    let pauseMessage = state.bin?.isPaused ? " - " + state.bin.reasonForPause : "";

    return (
        <BinDTOContext.Provider value={state.bin}>
            <Layout.Content ref={divRef} className="dashboard">
                {!offline ? (
                    <Spin spinning={buttonLoadingFeedBack !== "Done"}>
                        <Row>
                            {state.currentStep === CurrentStep.FailSafe ? (
                                <Alert message="This bin is in fail-safe mode." type="error" closable={false} />
                            ) : null}
                            <PageHeader
                                style={{ width: "100%", paddingRight: 16, paddingLeft: 10 }}
                                onBack={() => {
                                    // key unique to the location, only present if we came to this page from another first using React Router. ex. clicking details in BinDetails.
                                    // https://reactrouter.com/en/main/start/concepts#locations
                                    const hasHistory = !(location?.key == null);
                                    if (!hasHistory) {
                                        // going to the homepage is the best we can do currently (no knowledge of prior history or upper route/component to go back to)
                                        history.push(Routes.HOME_ROUTE);
                                    } else {
                                        history.goBack();
                                    }
                                }}
                                title={binInfo.name}
                                subTitle={
                                    <Col xs={0} sm={0} md={24} lg={24} xl={24}>
                                        <Space direction="horizontal" size={"small"}>
                                            <BinOfflineIndicator binDTO={state.bin} />
                                            {`Last Refreshed: ${state.refreshTime?.format("MM/DD/YYYY h:mm a") ??
                                                "---"}`}
                                        </Space>
                                    </Col>
                                }
                                extra={getPageHeaderButtons(isAdmin, automationType)}
                            />
                        </Row>

                        <div style={{ paddingBottom: "8px" }}>

                            <Card>
                                {state.currentStep === CurrentStep.Idle && <IdleHelpText />}
                                {state.currentStep === CurrentStep.Fill && <FillModeHelpText />}
                                {state.currentStep === CurrentStep.Dry && <DryModeHelpText />}
                                {state.currentStep === CurrentStep.Storage && <StorageModeHelpText />}
                                {state.currentStep === CurrentStep.Unload && <UnloadModeHelpText />}
                                {state.currentStep === CurrentStep.Manual && <UserControlModeHelpText />}
                            </Card>
                        </div>
                        <Modal
                            key="loading"
                            centered={true}
                            footer={null}
                            closable={false}
                            bodyStyle={{ padding: 16 }}
                            width={window.innerWidth >= 1200 ? "20%" : window.innerWidth > 960 ? "30%" : "50%"}
                            open={buttonLoadingFeedBack !== "Done"}
                        >
                            {buttonLoadingFeedBack === "StartManualDry" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={15} sm={15} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Entering Manual Dry Mode</p>
                                    </Col>
                                </Row>
                            )}

                            {buttonLoadingFeedBack === "StopManualDry" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={15} sm={15} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Leaving Manual Dry Mode</p>
                                    </Col>
                                </Row>
                            )}

                            {buttonLoadingFeedBack === "Fill" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={15} sm={15} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Turning on Fill Mode, Warning Fan Will Be Starting, Stand Clear</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "Dry" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={14} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Starting Dry Mode. Please stand clear of the bin. This will take a minute...</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "Idle" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={6} md={7} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={18} md={17} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Entering Idle mode, Fan Shutting Off</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "Storage" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={6} md={7} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={18} md={17} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Entering Storage Mode</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "Unload" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={6} md={7} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={18} md={17} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Entering Unload Mode</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "Pause" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={6} md={7} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={18} md={17} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Pausing Drying, Fan Shutting Off</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "retFill" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={6} md={7} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={18} md={17} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Stopping Drying, Returing To Fill Mode, The Fan Is Still Running</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "Resume" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={8} sm={6} md={7} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={14} sm={18} md={17} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Resuming From last Place Paused</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "EnteringManualMode" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={15} sm={15} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Entering User Control Mode</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "ExitingManualMode" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={15} sm={15} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Exiting User Control Mode</p>
                                    </Col>
                                </Row>
                            )}
                            {buttonLoadingFeedBack === "ExitingFailSafe" && (
                                <Row justify="space-around" align="middle">
                                    <Col xs={7} sm={7} md={6} lg={6} xl={6}>
                                        <WarningTwoTone style={{ fontSize: 92 }} twoToneColor={["#fff", "#faad14"]} />
                                    </Col>
                                    <Col xs={15} sm={15} md={18} lg={14} xl={14}>
                                        <h1 style={{ fontWeight: "lighter" }}>
                                            Loading&nbsp;&nbsp;
                                            <LoadingOutlined spin={true} style={{ color: "#ccbe4a" }} />
                                        </h1>
                                        <p style={{ fontWeight: "bold" }}>Exiting Fail Safe Mode</p>
                                    </Col>
                                </Row>
                            )}
                        </Modal>
                        <Modal
                            key="UnloadModal"
                            centered={true}
                            open={state.emptyVisible}
                            title="Unload Grain"
                            onCancel={() => setState({ emptyVisible: false })}
                            destroyOnClose={true}
                            onOk={emptyBinSubmit}
                        >
                            <Form>
                                <Form.Item {...layout} label="Load Time" name="loadTime">
                                    <DatePicker style={{ width: "100%" }} showTime={true} format="MM/DD/YYYY h:mm a" />
                                </Form.Item>
                                <Form.Item {...layout} rules={[RequiredRule]} label="Estimated Bu" name="estimatedBu">
                                    <InputNumber addonAfter="k bu" step={0.5} />
                                </Form.Item>
                                <Form.Item {...layout} label="Bin Cleaned / Batch End" name="binCleaned">
                                    <Checkbox checked={state.batchEnd} onClick={() => setState({ batchEnd: !state.batchEnd })} />
                                </Form.Item>
                                <Form.Item {...layout} rules={[RequiredRule]} label="Destination" name="destination">
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            width="600px"
                            key="startFill"
                            centered={true}
                            open={state.addFillVisible}
                            destroyOnClose={true}
                            afterOpenChange={() => {
                                startFillForm.setFieldsValue(populatefillFormInitialValues());
                            }}
                            title={"Start Fill & Aerate Mode"}
                            onCancel={closeFillOrAddLoadDialog}
                            footer={[
                                <Button key="back" onClick={closeFillOrAddLoadDialog}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" loading={state.pendingAddFill} form="fill" htmlType="submit">
                                    {"Start Fill & Aerate Mode"}
                                </Button>
                            ]}
                        >
                            <Form form={startFillForm} onFinish={startFillMode} initialValues={populatefillFormInitialValues()} id="fill">
                                <Typography.Text strong>Batch settings</Typography.Text>
                                <br />

                                <Form.Item {...layout2} label="Start a New Batch?" name="newBatch">
                                    <Checkbox
                                        disabled={state.forcedNewBatch}
                                        checked={state.newBatchCheck}
                                        onClick={() => setState({ newBatchCheck: !state.newBatchCheck })}
                                    />
                                </Form.Item>
                                {state.newBatchCheck && (
                                    <Form.Item {...layout2} label="Grain Type" name="grainType" rules={[RequiredRule]}>
                                        <Select>
                                            <Select.Option value="Corn">Corn</Select.Option>
                                            <Select.Option value="Rye">Rye</Select.Option>
                                            <Select.Option value="Soybeans">Soybeans</Select.Option>
                                            <Select.Option value="Wheat">Wheat</Select.Option>
                                        </Select>
                                    </Form.Item>
                                )}

                                <Divider />

                                <Typography.Paragraph>
                                    <Typography.Text strong>Fan Settings</Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        Fan will run when incoming air EMC is b/t {fillEmpty(startFillFormValues?.emcMin)} &{" "}
                                        {fillEmpty(startFillFormValues?.emcMax)}% & temp is b/t {fillEmpty(startFillFormValues?.tempMin)} &{" "}
                                        {fillEmpty(startFillFormValues?.tempMax)}℉
                                    </Typography.Text>
                                </Typography.Paragraph>

                                <Form.Item {...layout2} name="emcMin" label="EMC Min" rules={[RequiredRule]}>
                                    <InputNumber<number | string> addonAfter="%" step={0.5} min={0} max={100} />
                                </Form.Item>
                                <Form.Item {...layout2} name="emcMax" label="EMC Max" rules={[RequiredRule]}>
                                    <InputNumber<number | string> addonAfter="%" step={0.5} min={0} max={100} />
                                </Form.Item>
                                <Form.Item {...layout2} name="tempMin" label="Temperature Min" rules={[RequiredRule]}>
                                    <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                </Form.Item>

                                <Form.Item {...layout2} name="tempMax" label="Temperature Max" rules={[RequiredRule]}>
                                    <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            width="600px"
                            key="addLoad"
                            centered={true}
                            open={state.addLoadVisible}
                            destroyOnClose={true}
                            title={"Add Load"}
                            onCancel={closeFillOrAddLoadDialog}
                            footer={[
                                <Button key="back" onClick={closeFillOrAddLoadDialog}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" htmlType="submit" form="addLoad" loading={state.pendingAddFill}>
                                    {"Add Load"}
                                </Button>
                            ]}
                        >
                            <Form
                                form={addLoadForm}
                                initialValues={populateAddLoadFormInitialValues()}
                                id="addLoad"
                                onFinish={addLoadToTable}
                                preserve={false}
                            >
                                <Form.Item {...layout} label="Load Time" name="loadTime">
                                    <DatePicker style={{ width: "100%" }} showTime={true} format="MM/DD/YYYY h:mm a" />
                                </Form.Item>

                                <Form.Item {...layout} rules={[RequiredRule]} label="Initial Moisture Content" name="initialMC">
                                    <InputNumber addonAfter="%" step={0.5} />
                                </Form.Item>

                                <Form.Item {...layout} rules={[RequiredRule]} label="Estimated Bu" name="estimatedBu">
                                    <InputNumber step={0.5} addonAfter="bu" />
                                </Form.Item>

                                <Form.Item {...layout} rules={[RequiredRule]} label="Source" name="source">
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            key="startDry"
                            centered={true}
                            open={state.endFillVisible}
                            onCancel={closeStartDryDialog}
                            destroyOnClose={true}
                            title="Start Drying - Review Loads"
                            okText="Start Drying"
                            afterOpenChange={() => {
                                startDryForm.setFieldsValue(populateDryFormInitialValues());
                            }}
                            okButtonProps={{ type: "primary", disabled: hasPendingLoadDeletes(), htmlType: "submit", form: "startDryForm" }}
                            footer={[
                                <Button key="back" onClick={closeStartDryDialog}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" htmlType="submit" form="startDryForm" disabled={hasPendingLoadDeletes()}>
                                    Start Drying
                                </Button>
                            ]}
                            bodyStyle={{ padding: 0 }}
                            width="60%"
                        >
                            <Row justify="center">
                                <Col span={24}>
                                    <Form
                                        form={startDryForm}
                                        onFinish={startDrying}
                                        initialValues={populateDryFormInitialValues()}
                                        scrollToFirstError
                                        id="startDryForm"
                                    >
                                        <Typography.Text strong>Batch Settings</Typography.Text>
                                        <br />
                                        <Form.Item {...layout2} label="Confirm Grain Type" name="grainType" rules={[RequiredRule]}>
                                            <Select>
                                                <Select.Option value="Corn">Corn</Select.Option>
                                                <Select.Option value="Rye">Rye</Select.Option>
                                                <Select.Option value="Soybeans">Soybeans</Select.Option>
                                                <Select.Option value="Wheat">Wheat</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Divider />

                                        <Typography.Paragraph>
                                            <Typography.Text strong>Fan Settings</Typography.Text>
                                            <br />
                                            <Typography.Text>
                                                Fan will run when incoming air EMC is b/t {fillEmpty(startDryFormValues?.emcMin)} &{" "}
                                                {fillEmpty(startDryFormValues?.emcMax)}% & temp is b/t {fillEmpty(startDryFormValues?.tempMin)} &{" "}
                                                {fillEmpty(startDryFormValues?.tempMax)}℉.
                                            </Typography.Text>
                                        </Typography.Paragraph>

                                        <Form.Item {...layout2} name="emcMin" label="EMC Min" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="%" step={0.5} min={0} max={100} />
                                        </Form.Item>

                                        <Form.Item {...layout2} name="emcMax" label="EMC Max" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="%" step={0.5} min={0} max={100} />
                                        </Form.Item>
                                        <Form.Item {...layout2} name="tempMin" label="Temperature Min" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                        </Form.Item>

                                        <Form.Item {...layout2} name="tempMax" label="Temperature Max" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                        </Form.Item>

                                        <Divider />

                                        <Typography.Paragraph>
                                            <Typography.Text strong>Heater Settings</Typography.Text>
                                            <br />
                                            <Typography.Text>
                                                Heater will run when target layer's grain temp is below{" "}
                                                {fillEmpty(startDryFormValues?.maxLayerGrainTemperature)}.
                                            </Typography.Text>
                                        </Typography.Paragraph>

                                        <Form.Item {...layout2} name="heaterMode" label="Heater Mode" rules={[RequiredRule]}>
                                            <Select>
                                                <Select.Option value={NewHeaterMode.AUTO}>Auto</Select.Option>
                                                <Select.Option value={NewHeaterMode.ON}>Always On</Select.Option>
                                                <Select.Option value={NewHeaterMode.OFF}>Always Off</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            hidden={startDryFormValues?.heaterMode != NewHeaterMode.AUTO}
                                            {...layout2}
                                            name="maxLayerGrainTemperature"
                                            label="Max Layer Grain Temp"
                                            rules={[RequiredRule]}
                                        >
                                            <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                        </Form.Item>

                                        <Form.Item
                                            hidden={
                                                // state.bin?.desiredProperties?.overrides?.noHeater === true ||
                                                state.bin?.hasHeaters === false || 
                                                startDryFormValues?.heaterMode != NewHeaterMode.AUTO
                                            }
                                            {...layout2}
                                            name="heaterPlenumMcOffset"
                                            label="Heater EMC Offset"
                                            rules={[
                                                {
                                                    type: "number",
                                                    max: 20,
                                                    message: "Max is 20%"
                                                }
                                            ]}
                                            tooltip={<HeaterMcReductionHelpText />}
                                        >
                                            <InputNumber<number | string> addonAfter="%" step={0.5} />
                                        </Form.Item>

                                        <Divider />

                                        <Space direction="horizontal" wrap>
                                            <Typography.Text strong>Layer Target Settings</Typography.Text>
                                            {/* <Popover overlayStyle={{maxWidth: "400px"}} trigger={['click', 'focus', 'hover']} content={<DryLayerAdjustHelpText />}>
                                        <QuestionCircleOutlined />
                                    </Popover> */}
                                            <DryLayerAdjustHelpText />
                                        </Space>
                                        <LayerAdjustmentList binDTO={state.bin!} />
                                    </Form>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                    <p style={{ fontWeight: "bolder", paddingTop: 16 }}>Review the loads entered then, if correct, click Start Drying</p>
                                </Col>
                                <Col span={24}>
                                    <Collapse defaultActiveKey={["loads"]}>
                                        <Collapse.Panel
                                            key="loads"
                                            header="Loads Entered"
                                            extra={
                                                <Space direction="horizontal" wrap>
                                                    <Button icon={<PlusOutlined />} onClick={() => setState({ addLoadVisible: true })}>
                                                        Add Load
                                                    </Button>
                                                    <span style={{ marginRight: "8px" }}></span> {/* Add spacing */}
                                                    <Button icon={<PlusOutlined />} onClick={() => setState({ addUnloadVisible: true })}>
                                                        Add Unload
                                                    </Button>
                                                </Space>
                                            }

                                        // {
                                        //     <Row style={{ padding: 10 }} gutter={10}>
                                        //         <Col span={24} style={{ paddingBottom: 10 }}>
                                        //             <Button
                                        //                 style={{ width: '100%' }}
                                        //                 onClick={() => setState({ addLoadVisible: true })}>
                                        //                 <PlusOutlined /> Add Load
                                        //             </Button>
                                        //         </Col>
                                        //     </Row>
                                        // }
                                        >
                                            <ReviewLoadTable
                                                loadData={state.loads}
                                                deleteLoad={deleteLoad}
                                                hasPendingLoadDeletes={hasPendingLoadDeletes}
                                                pendingLoadDeletes={state.pendingDeleteLoad}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </Modal>
                        <Modal
                            key="Storage"
                            centered={true}
                            open={state.showStorageDialog}
                            onCancel={closeStorageForm}
                            destroyOnClose={true}
                            afterOpenChange={() => {
                                storageForm.setFieldsValue(populateStorageFormInitialValues());
                            }}
                            title="Enter Storage"
                            okText="Enter Storage"
                            footer={[
                                <Button key="back" onClick={closeStorageForm}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" htmlType="submit" form="Storage">
                                    Enter Storage
                                </Button>
                            ]}
                            bodyStyle={{ padding: 0 }}
                            width="60%"
                        >
                            <Row justify="center">
                                <Col span={24}>
                                    <Form
                                        id="Storage"
                                        form={storageForm}
                                        // Clear on unmount
                                        preserve={false}
                                        onFinish={storage}
                                        initialValues={populateStorageFormInitialValues()}
                                    >
                                        <Typography.Paragraph>
                                            <Typography.Text strong>Fan Settings</Typography.Text>
                                            <br />
                                            <Typography.Text>
                                                Fan will run when incoming air EMC is b/t {fillEmpty(storageFormValues?.emcMin)} &{" "}
                                                {fillEmpty(storageFormValues?.emcMax)}% & temp is b/t {fillEmpty(storageFormValues?.tempMin)} &{" "}
                                                {fillEmpty(storageFormValues?.tempMax)}℉
                                            </Typography.Text>
                                        </Typography.Paragraph>
                                        <Form.Item {...layout2} name="emcMin" label="EMC Min" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="%" step={0.5} min={0} max={100} />
                                        </Form.Item>

                                        <Form.Item {...layout2} name="emcMax" label="EMC Max" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="%" step={0.5} min={0} max={100} />
                                        </Form.Item>
                                        <Form.Item {...layout2} name="tempMin" label="Temperature Min" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                        </Form.Item>

                                        <Form.Item {...layout2} name="tempMax" label="Temperature Max" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter="℉" step={0.5} />
                                        </Form.Item>

                                        <Form.Item {...layout2} name="fanTimerWeeklyHours" label="Fan Timer Weekly (hours)" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter=" hours" step={0.5} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal>
                        <Modal
                            key="startManual"
                            centered={true}
                            open={state.showManualEnterModal}
                            onCancel={() => toggleManualStartModal(false)}
                            destroyOnClose={true}
                            title="Enter Manual"
                            okText="Enter Manual"
                            footer={[
                                <Button key="back" onClick={() => toggleManualStartModal(false)}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" htmlType="submit" form="startManual">
                                    Enter Manual
                                </Button>
                            ]}
                            bodyStyle={{ padding: 0 }}
                        >
                            <Row justify="center">
                                <Col span={24}>
                                    <Form
                                        id="startManual"
                                        form={startManualForm}
                                        // Clear on unmount
                                        preserve={false}
                                        onFinish={startManualWithTimer}
                                        initialValues={populateStartManualFormValues()}
                                    >
                                        <Form.Item {...layout2} name="fanTimerHours" label="Fan Timer (hours)" rules={[RequiredRule]}>
                                            <InputNumber<number | string> addonAfter=" hours" step={0.5} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal>
                        <UnloadForm
                            open={state.addUnloadVisible}
                            pendingSubmit={state.pendingAddUnload}
                            onCancel={closeAddUnload}
                            onSubmit={handleSubmitUnloadForm}
                        />
                        <Modal
                            key={`changeGrainType`}
                            centered={true}
                            open={state.showChangeGrainType}
                            onCancel={() => toggleChangeGrainTypeModal(false)}
                            destroyOnClose={true}
                            title="Change Grain Type"
                            bodyStyle={{ padding: 0 }}
                            footer={[
                                <Button key="back" onClick={() => toggleChangeGrainTypeModal(false)}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" htmlType="submit" form="changeGrainType">
                                    Change Grain Type
                                </Button>
                            ]}
                        >
                            <Row justify="center">
                                <Col span={24}>
                                    <Form id="changeGrainType" onFinish={changeGrainTypeFormMethods.handleSubmit(changeGrainTypeHandleSubmit)}>
                                        <FormItem {...layout2} label="Grain Type" name="grainType" control={changeGrainTypeFormMethods.control}>
                                            <Select defaultValue={GrainType.Rye}>
                                                <Select.Option value="Corn">Corn</Select.Option>
                                                <Select.Option value="Rye">Rye</Select.Option>
                                                <Select.Option value="Soybeans">Soybeans</Select.Option>
                                                <Select.Option value="Wheat">Wheat</Select.Option>
                                            </Select>
                                        </FormItem>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal>
                        {state.bin && (
                            <ManualFanSettingsModal
                                binDTO={state.bin}
                                deviceId={props.binInfo?.deviceId!}
                                open={state.showManualFanSettingsModal && hasFans}
                                fanSettings={convertFanSettingsToFormSettings()}
                                onSubmit={async values => {
                                    const success = await handleFanSettingsSubmit(values);
                                    if (success) {
                                        setState({ showManualFanSettingsModal: false });
                                    }
                                    return success;
                                }}
                                onCancel={() => {
                                    setState({ showManualFanSettingsModal: false });
                                }}
                            />
                        )}
                        {/*//* progress bar */}
                        <div hidden={state.currentStep !== CurrentStep.FailSafe} style={{ paddingBottom: 15 }}>
                            <Row align="middle">
                                {/* <Col span={12} >
                                <Tag color={'#FF0000'} style={{ borderColor: '#000', color: '#000' }}>{'In Fail Safe'}</Tag>
                            </Col> */}
                                <Space>
                                    <Col>
                                        <PopconfirmYesNo title="Exit Fail Safe? (click Refresh twice after use)" onConfirm={exitFailSafe}>
                                            <Button shape="round" size="small">
                                                Exit Fail Safe
                                            </Button>
                                        </PopconfirmYesNo>
                                    </Col>
                                    <Col>
                                        <PopconfirmYesNo title="Enter Idle Mode?" onConfirm={returnToIdle}>
                                            <Button shape="round" size="small">
                                                Enter Idle
                                            </Button>
                                        </PopconfirmYesNo>
                                    </Col>
                                    <Col>
                                        <Button
                                            shape="round"
                                            size="small"
                                            onClick={() => {
                                                startManual();
                                            }}
                                        >
                                            Enter User Control Mode
                                        </Button>
                                    </Col>
                                </Space>
                            </Row>
                        </div>
                        {/* {automationType === AutomationType.DriStack && state.bin && <Row align="middle">
                        <Col span={24}>
                            <Steps
                                current={state.currentStep}
                                onChange={onStepChange}
                                style={{ width: '100%', paddingBottom: 10 }}>
                                <Step title="Idle" />
                                <Step title="Fill" />
                                <Step title="Dry" />
                                <Step title="Storage" />
                                <Step title="Unload" />
                            </Steps>
                        </Col>
                    </Row>} */}
                        <Row gutter={10} align="top" style={{ paddingBottom: 0 }}>
                            <Col
                                xs={{ span: 24, order: 2 }}
                                sm={{ span: 24, order: 2 }}
                                md={{ span: 24, order: 2 }}
                                lg={{ span: 15, order: 1 }}
                                xl={{ span: 16, order: 1 }}
                            >
                                <Row>
                                    <Col
                                        xs={{ span: 24, order: 1 }}
                                        sm={{ span: 24, order: 1 }}
                                        md={{ span: 24, order: 1 }}
                                        lg={{ span: 24, order: 2 }}
                                        xl={{ span: 24, order: 2 }}
                                        style={{ paddingBottom: 10 }}
                                    >
                                        <Skeleton loading={state.loading} active={true}>
                                            {state.bin?.fans && (
                                                <Card title="Fan Settings">
                                                    <Row justify={"end"}>
                                                        <Col>
                                                            <RefreshButton
                                                                refresh={refreshBinAndErrors}
                                                                disabled={state.fetchingBinFromDevice}
                                                                loading={state.fetchingBinFromDevice}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {hasFans && (
                                                        <Skeleton loading={state.bin == null}>
                                                            {/* <Card title="Fan Settings"> */}
                                                            <WeatherMonitor
                                                                deviceId={props.binInfo?.deviceId!}
                                                                operatingMode={state.bin?.operatingMode!}
                                                                loading={state.bin == null}
                                                                binDTO={state.bin!}
                                                                onSubmit={handleFanSettingsSubmit}
                                                                newFanSettings={convertFanSettingsToFormSettings()}
                                                            />
                                                            {/* </Card>     */}
                                                        </Skeleton>
                                                    )}
                                                </Card>
                                            )}
                                            {/* Something is wrong that it requires a manual div with padding to get padding between rows to work. Perhaps A Row within a Col? */}
                                            {hasFans && selectAutomationType(state.bin) === AutomationType.DriStack && (
                                                <div style={{ paddingTop: "8px" }}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Skeleton loading={state.bin == null} active>
                                                                {state.bin && (
                                                                    <HeaterControls
                                                                        binDTO={state.bin}
                                                                        binId={state.binInfo?.id}
                                                                        azureDeviceId={props.binInfo?.deviceId!}
                                                                    />
                                                                )}
                                                            </Skeleton>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                            {/* Something is wrong that it requires a manual div with padding to get padding between rows to work. Perhaps A Row within a Col? */}
                                            {state.bin != null && selectAutomationType(state.bin) === AutomationType.DriStack && (
                                                <div style={{ paddingTop: "8px" }}>
                                                    <Row gutter={[8, 16]}>
                                                        <Col span={24}>
                                                            <Skeleton loading={state.bin == null} active>
                                                                {state.bin && (
                                                                    <CompressorControls
                                                                        binDTO={state.bin}
                                                                        binId={props.binInfo?.id}
                                                                        azureDeviceId={props.binInfo?.deviceId!}
                                                                    />
                                                                )}
                                                            </Skeleton>
                                                        </Col>

                                                        {(isAdmin || inManualMode) && (
                                                            <Col span={24} style={{ paddingBottom: "8px" }}>
                                                                <Skeleton loading={state.bin == null} active>
                                                                    <Card
                                                                        title="Valve Positions"
                                                                        extra={
                                                                            <Row gutter={8} justify="end">
                                                                                {
                                                                                    <React.Fragment>
                                                                                        <Col>
                                                                                            <RefreshButton
                                                                                                loading={state.fetchingBinFromDevice}
                                                                                                disabled={state.fetchingBinFromDevice}
                                                                                                refresh={() => fetchBin(props.binInfo?.deviceId!)}
                                                                                            />
                                                                                        </Col>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </Row>
                                                                        }
                                                                    >
                                                                        {state.bin != null && (
                                                                            <ManualRoutineControl
                                                                                binDTO={state.bin}
                                                                                deviceId={props.binInfo?.deviceId!}
                                                                                binId={props.binInfo?.id!}
                                                                                highlightArr={[]}
                                                                                highlightStack={(stackId: string) => { }}
                                                                            />
                                                                        )}
                                                                    </Card>
                                                                </Skeleton>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </div>
                                            )}

                                            <div>
                                                {state.bin?.desiredProperties?.isSeedBin && (
                                                    <Row>
                                                        <Col span={24}>
                                                            <RoutineList binId={props.binInfo?.id} url="bla" />
                                                            {/* <Space direction='horizontal' size="middle"> */}
                                                            {/* <UploadRoutine binId={state.binInfo.id} /> */}
                                                            {/* <UploadAndRunRoutine binId={state.binInfo.id} /> */}
                                                            {/* </Space> */}
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>

                                            {/* Something is wrong that it requires a manual div with padding to get padding between rows to work. Perhaps A Row within a Col? */}
                                            {selectAutomationType(state.bin) === AutomationType.DriStack &&
                                                [OperatingMode.Dry, OperatingMode.TopDry, OperatingMode.PreDry].includes(state.bin?.operatingMode!) && (
                                                    <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                                        <DryLayerAdjust binDTO={state.bin!} deviceId={props.binInfo?.deviceId!} />
                                                    </div>
                                                )}

                                            {/* {hasFans && binConfiguredWithHeaters(bin) && <>
                                            <Card bodyStyle={{ padding: 8 }}>
                                                <Row gutter={[8, 9]}>
                                                    <Col>
                                                        <span>Heaters</span>
                                                    </Col>
                                                    <Col>
                                                        <Space style={{ display: "flex" }}>
                                                            <Popconfirm
                                                                title="Turn off all Heaters?"
                                                                okText="Yes"
                                                                cancelText="No"
                                                                disabled={!inManualMode}
                                                                onConfirm={turnOffHeaters}>
                                                                <Button size="small" disabled={!inManualMode}>Turn Off</Button>
                                                            </Popconfirm>
                                                            <Popconfirm
                                                                title="Turn on all Heaters?"
                                                                okText="Yes"
                                                                cancelText="No"
                                                                disabled={!inManualMode}
                                                                onConfirm={turnOnHeaters}>
                                                                <Button size="small" disabled={!inManualMode}>Turn On</Button>
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Space>
                                                        <Col>
                                                            Mode: {formatHeaterMode(bin?.grain?.heaterMode!)}
                                                        </Col>
                                                    </Space>
                                                </Row>
                                                <Row>
                                                    <Button onClick={onChangeHeaterModeClick}>Change Mode</Button>
                                                    <ChangeHeaterModeModal deviceId={state.binInfo?.deviceId!} onOk={onHeaterModeChangeModalOk} onCancel={onHeaterModeChangeModalCancel} open={state.isOpenHeaterChange} currentHeaterMode={bin?.grain?.heaterMode ?? HeaterMode.EnergyEff} />
                                                </Row>
                                                <Row>
                                                    Suggested Set Point: {heaterRecommendedTempFormatted(state.bin)}
                                                </Row>
                                            </Card>
                                        </>}

                                        {
                                            binConfiguredWithHeaters(bin) && (bin?.fans || []).map((fan) => (
                                                <Card bodyStyle={{ padding: 8 }}>
                                                    <Row >
                                                        <Col xs={6} md={3}>Heater {fan.id}</Col>
                                                        <Col>
                                                            {(fan?.isHeaterOn
                                                                ? <Popconfirm
                                                                    title="Turn off this heater?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    disabled={!inManualMode}
                                                                    onConfirm={() => turnOffHeater(fan.id!)}>
                                                                    <Tag style={{ textAlign: 'center' }} color={inManualMode ? "success" : DISABLED_TAG_COLOR}>On</Tag>
                                                                </Popconfirm>
                                                                : <Popconfirm
                                                                    title="Turn on this heater?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    disabled={!inManualMode}
                                                                    onConfirm={() => turnOnHeater(fan.id!)}>
                                                                    <Tag color={inManualMode ? "error" : DISABLED_TAG_COLOR}>Off</Tag>
                                                                </Popconfirm>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {(bin?.hardwareYear ?? 0) >= 2022 &&
                                                        <Row>
                                                            <Col>
                                                                Current: <b>{Number(fan.heaterAmps).toFixed(2)}</b> Amps
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Card>
                                            ))
                                        }

                                        {selectAutomationType(state.bin) === AutomationType.DriStack && <section style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
                                            <CompressorCard isCompressorOn={bin?.isCompressorOn} turnOffCompressor={turnOffCompressor} turnOnCompressor={turnOnCompressor} operatingMode={bin?.operatingMode} hardwareYear={bin?.hardwareYear ?? 2021} compressorAmps={bin?.compressorAmpDraw} psi={bin?.pneumaticLinePressure} />
                                        </section>} */}

                                            <Card
                                                title={
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <span>Fill Logs</span>
                                                        <Space>
                                                            <Button
                                                                icon={<PlusOutlined />}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    setState({ addLoadVisible: true });
                                                                }}
                                                            >
                                                                Add Load
                                                            </Button>
                                                            <Button
                                                                icon={<PlusOutlined />}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    setState({ addUnloadVisible: true });
                                                                }}
                                                            >
                                                                Add Unload
                                                            </Button>
                                                        </Space>
                                                    </div>
                                                }
                                            >
                                                {state.bin != null && selectAutomationType(state.bin) === AutomationType.DriStack && (
                                                    <FillLoadTable
                                                        loadData={state.loads}
                                                        deleteLoad={deleteLoad}
                                                        hasPendingLoadDeletes={hasPendingLoadDeletes}
                                                        pendingLoadDeletes={state.pendingDeleteLoad}
                                                    />
                                                )}
                                            </Card>
                                        </Skeleton>
                                    </Col>
                                    {binInfo.id ? (
                                        <BinVisualThree
                                            currentStep={state.currentStep}
                                            bin={state.bin}
                                            binInfo={binInfo}
                                            isLoading={setLoading}
                                            updateBin={updateBin}
                                            activeErrors={state.activeErrors}
                                            fetchingFromDevice={state.fetchingBinFromDevice}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Row>
                            </Col>
                            <Col
                                xs={{ span: 24, order: 1 }}
                                sm={{ span: 24, order: 1 }}
                                md={{ span: 24, order: 1 }}
                                lg={{ span: 9, order: 2 }}
                                xl={{ span: 8, order: 2 }}
                            >
                                <Row>
                                    {isAdmin && (
                                        <Col span={24} style={{ paddingBottom: "10px" }}>
                                            <BinStatusCard bin={state.bin} binInfo={state.binInfo} inBinVisual={false} />
                                        </Col>
                                    )}
                                    <Col span={24} style={{ paddingBottom: "10px" }}>
                                        <Card title="System Status" size="small" loading={state.loading} bodyStyle={{ paddingBottom: 0 }}>
                                            <Descriptions
                                                bordered={true}
                                                // column={{ xxl: 2, xl: 1, lg: 1, md: 2, sm: 1, xs: 1 }}
                                                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                                size="small"
                                            >
                                                <Item label="Mode">
                                                    <Typography.Text>{operatingModeText}</Typography.Text>
                                                    {bin?.isPaused === true && (
                                                        <Typography.Text style={{ color: "red" }} strong>
                                                            &nbsp;(PAUSED)
                                                        </Typography.Text>
                                                    )}
                                                </Item>

                                                {showCurrentRoutine && <Item label="Current Routine">{renderRoutineInfo(pauseMessage)}</Item>}

                                                {(state.bin?.fans ?? []).map(fan => {
                                                    return (
                                                        <Item label={`Fan ${fan.number}`}>
                                                            {fan.isOn == null ? "" : fan.isOn ? "On" : "Off"} (
                                                            {formatNumber(fan?.fan?.current?.current_mA! / 1000.0, {
                                                                decimalPlaces: 2,
                                                                filler: "",
                                                                suffix: " Amps"
                                                            })}
                                                            )
                                                        </Item>
                                                    );
                                                })}
                                                {hasHeater &&
                                                    (state.bin?.fans ?? []).map(fan => {
                                                        return (
                                                            <Item label={`Heater ${fan.number}`}>
                                                                {fan.isHeaterOn == null ? "" : fan.isHeaterOn ? "On" : "Off"} (
                                                                {formatNumber(fan?.heaterAmps, { decimalPlaces: 2, filler: "", suffix: " Amps" })})
                                                            </Item>
                                                        );
                                                    })}

                                                {automationType === AutomationType.DriStack && (
                                                    <Item label="Compressor (PSI)">
                                                        {formatBool(state.bin?.compressorState?.isOn, {
                                                            true: "On",
                                                            false: "Off",
                                                            null: "",
                                                            bold: false
                                                        })}{" "}
                                                        (
                                                        {formatNumber(state.bin?.compressorState?.inlinePressure?.psi, {
                                                            decimalPlaces: 1,
                                                            suffix: " PSI"
                                                        })}
                                                        )
                                                    </Item>
                                                )}
                                            </Descriptions>

                                            {!isAdmin && (
                                                <div style={{ paddingTop: "10px", paddingBottom: "10px", display: "flex", justifyContent: "center" }}>
                                                    <Button style={{ marginRight: "15px" }} onClick={() => downloadAsciiBinState(state.bin!)}>
                                                        Download Diagnostic File
                                                    </Button>
                                                    <RestartDriStackModuleButton deviceId={props.binInfo?.deviceId} />
                                                </div>
                                            )}
                                        </Card>
                                    </Col>
                                </Row>
                                {state.bin?.hasCamera && (
                                    <Card title="Bin Camera">
                                        <Row style={{ paddingTop: "16px" }} justify={"center"}>
                                            <Col>
                                                <CameraImage binId={props.binInfo?.id!} />
                                            </Col>
                                        </Row>
                                    </Card>
                                )}
                                <Row>
                                    <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Col span={24} style={{ paddingBottom: "10px" }}>
                                            {state.bin != null && selectAutomationType(state.bin) === AutomationType.DriStack && (
                                                <TopStackTableAvg tableData={state.topStackTableAvgInfo} />
                                            )}
                                        </Col>
                                        <Col span={24} style={{ paddingBottom: "10px" }}>
                                            {state.bin != null && selectAutomationType(state.bin) === AutomationType.DriStack && (
                                                <LayersAverageTable tableData={state.layerAvgTableInfo} binDTO={state.bin} />
                                            )}
                                        </Col>
                                        <Col span={24}>
                                            {hasFans && (
                                                <Card size="small" bodyStyle={{ padding: 0, paddingTop: 10 }} title="Incoming Air Sensor Readings">
                                                    <Table size="small" dataSource={state.miscBinInfoTableData} pagination={false} showHeader={true}>
                                                        <Column title="" dataIndex="Name" key="Name" />
                                                        <Column
                                                            title="Temperature (°F)"
                                                            dataIndex="Temperature"
                                                            key="Temperature"
                                                            render={value => formatNumber(value, { decimalPlaces: 0, filler: "" })}
                                                        />
                                                        <Column
                                                            title="DP (°F)"
                                                            dataIndex={"dewPoint"}
                                                            key="dewPoint"
                                                            render={value => formatNumber(value, { decimalPlaces: 0, filler: "" })}
                                                        />
                                                        <Column
                                                            title="RH (%)"
                                                            dataIndex="RelativeHumidity"
                                                            key="RelativeHumidity"
                                                            render={value => formatNumber(value, { decimalPlaces: 0, filler: "" })}
                                                        />

                                                        <Column
                                                            title="EMC (%)"
                                                            dataIndex="EMC"
                                                            key="EMC"
                                                            render={value => formatNumber(value, { decimalPlaces: 1, filler: "" })}
                                                        />
                                                    </Table>
                                                </Card>
                                            )}
                                        </Col>
                                    </Row>
                                </Row>
                                {/* <Row>
                                <Col span={24} style={{ paddingBottom: 10 }}>
                                    <Skeleton loading={state.loading} active={true} paragraph={{ rows: 10 }}>
                                        {isAdmin && <Card size="small" title="Moisture Content History - Current Batch"
                                            headStyle={{ backgroundColor: '#4d4d4d', color: '#fff' }}
                                            bodyStyle={{ padding: 10 }}
                                            loading={state.loading}>
                                            {!mcChartOffline ?
                                                <Spin spinning={state.graphData === undefined}>

                                                    <Line
                                                        data={{
                                                            datasets: [{

                                                                data: state.graphData,
                                                                backgroundColor: '#36a2eb',
                                                                fill: false,
                                                                showLine: true,
                                                            }]
                                                        }}

                                                        options={{
                                                            responsive: true,
                                                            animation: false,
                                                            maintainAspectRatio: false,
                                                            plugins: {
                                                                autocolors: {
                                                                    enabled: false,
                                                                },
                                                                title: {
                                                                    display: false,
                                                                },
                                                                legend: {
                                                                    display: false,
                                                                },
                                                                tooltip: {
                                                                    callbacks: {
                                                                        label: function (tooltipItem: any) {
                                                                            return 'Date: ' + tooltipItem.label + ' MC: ' + Number(tooltipItem.formattedValue);
                                                                        },
                                                                    }
    
                                                                }
                                                            },
                                                            scales: {
                                                                x: {
                                                                    type: 'time',
                                                                    time: {
                                                                        displayFormats: {
                                                                            quarter: 'MMM D h:mm:ss'
                                                                        }
                                                                    }
                                                                },
                                                                y: {
                                                                    display: true,
                                                                    suggestedMin: 25,
                                                                    beginAtZero: true,
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Spin> : <Result style={{ maxWidth: '' }} status="warning" title="No Data" />}
                                        </Card> }
                                    </Skeleton>
                                </Col>
                            </Row> */}

                                {/* <Row style={{justifyContent: "center"}}>
                            <Col  xs={24} sm={24} md={24} lg={18} xl={18} style={{ paddingRight: 0 }}>
                                    <Collapse>
                                    {renderedCrit}
                                    </Collapse>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ paddingRight: 0 }}>
                                    <Collapse>
                                        {renderedWarning}
                                    </Collapse>
                                </Col>
                            </Row> */}

                                {/* <Row>
                                <Col span={24} style={{ paddingBottom: 10 }}>
                                    <Spin spinning={state.binStatsData === undefined}>
                                        <Table dataSource={state.binStatsData} pagination={false} bordered={true} loading={state.loading}>
                                            <Column
                                                align="center"
                                                title="LEVEL"
                                                dataIndex="level"
                                                key="level"
                                            />
                                            <Column
                                                align="center"
                                                title="MC"
                                                dataIndex="mc"
                                                key="mc"
                                            />
                                            <Column
                                                align="center"
                                                title="T"
                                                dataIndex="t"
                                                key="t"
                                            />
                                            <Column
                                                align="center"
                                                title="MST"
                                                dataIndex="mst"
                                                key="mst"
                                            />
                                        </Table>
                                    </Spin>
                                </Col>
                            </Row> */}
                            </Col>
                        </Row>{" "}
                    </Spin>
                ) : (
                    <>
                        <PageHeader
                            style={{ width: "100%", paddingRight: 16, paddingLeft: 10 }}
                            onBack={() => {
                                props.history.goBack();
                            }}
                            title={binInfo.name}
                        />
                        <Result status="warning" title="Error Connecting To Bin, Bin Maybe Offline" />
                    </>
                )}
                <Row>
                    <Col span={24}>
                        <Card title="Charts" style={{ minHeight: "600px" }}
                            extra={<FanHeaterChangeBackgroundLegend />}>
                            {state.bin != null && props.binInfo?.id != null && props.binInfo?.deviceId != null
                                &&
                                <BinDTOContext.Provider value={state.bin}>
                                    <ChartsTab binDTO={state.bin} binId={props.binInfo?.id} deviceId={props.binInfo?.deviceId} />
                                </BinDTOContext.Provider>}
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Skeleton loading={state.loading}>
                            {state.binInfo.binLat && state.binInfo.binLng ? (
                                <FillingForcast lat={state.binInfo.binLat} lng={state.binInfo.binLng} />
                            ) : (
                                <Card title="Weather Forecast">
                                    <Result status="warning" title="Error Getting Weather Data" />
                                </Card>
                            )}
                        </Skeleton>
                    </Col>
                </Row>
            </Layout.Content>
        </BinDTOContext.Provider>
    );
};

export default withRouter(BinStatsPage);
