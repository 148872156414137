import * as React from 'react';

import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';

import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import Routes from 'src/consts/Routes';
import {
    Button,
    Card,
    Col,
    Row,
    Descriptions,
    Result,
    Tooltip,
    Skeleton, Tag, Spin, Collapse, Typography, Space
} from 'antd';

import BinDTO from 'src/models/BinDTO';

import BinInfoDTO from 'src/models/BinInfoDTO';
import BinApiService from 'src/api/BinApiService';
import Canvas from './Canvas';
import OfflineBinReportDTO from '../../../models/OfflineBinReportDTO';
import HeaterMode from 'src/consts/HeaterMode';
import { HistoryState } from '../BinStatusPage/BinStatsPage';
import { LogViewer } from '../BinStatusPage/LogViewer';
import { binConfiguredWithHeaters, downloadAsciiBinState } from '../BinStatusPage/BinVisualThree';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import PingAndBinDTO from 'src/models/PingAndBinDTO';
import { useHistory, useLocation } from 'react-router';
import { useCallback, useEffect, useRef } from 'react';
import { binDBKeys } from 'src/pages/binOverview/BinCommander';
import { useBinStateFromDevice } from 'src/queries/useBinStateFromDevice';
import { DeviceLogRangeForm } from '../BinStatusPage/DeviceLogsRangeForm';
import { LogType, viewBinStateLogsByRange, viewGrainStateLogsByRange, viewRunLogsByRange } from 'src/pages/shared/downloadLogsByRange';
import dayjs from 'dayjs';
import { formatBool, formatNumber } from '../BinStatusPage/HeaterControls';
import { BinOfflineIndicator, useExtraOfflineInfoContext } from '../BinOfflineIndicator';
import { BinDTOContext, useBinDTOContext } from 'src/queries/BinDTOContext';
import { BinInfoContext, useBinInfoContext } from 'src/queries/BinInfoContext';
const { Item } = Descriptions;
const { Panel } = Collapse;

export const getUserTimezoneOffset = (): number => {
    return new Date().getTimezoneOffset();
}

// interface State {
//     refreshing: boolean,
// }

interface Props {
    bin: BinInfoDTO;
    offlineReport: OfflineBinReportDTO | null;
    id: number;
    targetValue: number | null;
    binLength: number;
    isLoading(val: boolean, i: number): void;
}

type QueryTypes = Parameters<typeof useQuery>[2];

interface SnapShotReturn {
    bin: BinDTO | null,
    online: boolean,
    devicePing: boolean | null,
    snapshotOld: boolean,
    snapshotError: boolean,
}

export const isAnyHeaterOn = (binDTO: BinDTO): boolean | null => {
    const maybeFans = binDTO?.fans;
    if (maybeFans == null) {
        return null;
    }

    const fans = maybeFans;

    for (const fan of fans) {
        return fan.isHeaterOn;
    }
    return null;
}

const transformPingAndBinDTO = (response: PingAndBinDTO): SnapShotReturn => {
    let transformed: SnapShotReturn = {
        bin: response.binDTO,
        online: true,
        devicePing: null,
        snapshotOld: false,
        snapshotError: false,
    };
    try {
        if (response.binDTO !== null) {
            let online = true;
            const date = dayjs(new Date());
            const lastUpdateTime = dayjs(response.binDTO.captureTimeUtc);
            const snapShotAgeMinutes = date.diff(lastUpdateTime, 'minutes');
            if (snapShotAgeMinutes > 30) {
                online = false;
                transformed.snapshotOld = true;
            }
            transformed.devicePing = response.ping;
            transformed.online = online || response.ping;
        }
        else {
            transformed.snapshotError = true;
            transformed.devicePing = response.ping;
            transformed.online = response.ping;
        }
    } catch (err) {
        transformed.online = false;
    }
    return transformed;

}

export const deviceQueryKeys = {
    all: ["Devices"] as const,
    device: (azureDeviceID: string) => [...deviceQueryKeys.all, azureDeviceID] as const,
    devicePing: (azureDeviceID: string) => [...deviceQueryKeys.device(azureDeviceID), "ping"] as const,
    stateFromAzure: (azureDeviceID: string) => [...deviceQueryKeys.device(azureDeviceID), "AzureState"] as const,
    stateFromDevice: (azureDeviceID: string) => [...deviceQueryKeys.device(azureDeviceID), "State"] as const,
    offlineReport: (azureDeviceID: string) => [...deviceQueryKeys.device(azureDeviceID), "oflineReport"] as const,
    everyWareCloud: (azureDeviceID: string) => [...deviceQueryKeys.device(azureDeviceID), "EC"] as const,
};

export const useDevicePingAndReport = (deviceId: string, userTimeZoneOffset: number, options: QueryTypes = {}) => {
    const query = useQuery(deviceQueryKeys.devicePing(deviceId), async (q) => {
        return await BinApiService.pingDeviceAndGetBinDTOFromAzure(deviceId!, userTimeZoneOffset, q.signal)
    }, {
        enabled: options?.enabled,
        onError: () => options?.onError,
        select: transformPingAndBinDTO,
    });
    return query;
};

export const useOfflineReport = (azureDeviceID: string | null | undefined, enabled?: boolean) => {

    return useQuery(deviceQueryKeys.offlineReport(azureDeviceID!), async (q) => {
        if (azureDeviceID == null) {
            throw new Error("No Azure device supplied");
        }
        const report = await BinApiService.getOfflineConnectionReport(azureDeviceID!, q.signal);
        return report;
    }, {
        enabled: enabled,
    });
};

export const useEcDeviceQuery = (azureDeviceId: string | null | undefined, enabled: boolean) => {
    const query = useQuery(deviceQueryKeys.everyWareCloud(azureDeviceId!), async (q) => {
        if (azureDeviceId == null) {
            throw new Error("No azure device id supplied");
        }
        const res = await BinApiService.getECDevice(azureDeviceId, q.signal);
        return res;
    }, {
        enabled,
    });
    return query;
};

export enum ECConnectionStatus {
    MISSING = "MISSING",
    CONNECTED = "CONNECTED",
    DISCONNECTED = "DISCONNECTED",
}

interface ConnectionStatus {
    online: boolean,
    recentlyDisconnected?: boolean;
}

const ecDeviceIsOnline = (status: ECConnectionStatus | null | undefined): ConnectionStatus | null => {
    if (status == null) { return null }
    if (status === ECConnectionStatus.CONNECTED) { return { online: true }; }
    if (status === ECConnectionStatus.DISCONNECTED) { return { online: false, recentlyDisconnected: true }; }
    if (status === ECConnectionStatus.MISSING) { return { online: false, recentlyDisconnected: false }; }
    return null;
}

const BinDetails = (props: Props) => {
    const queryClient = useQueryClient();
    const extraOfflineInfoContext = useExtraOfflineInfoContext();

    const showExtraOfflineInfo = extraOfflineInfoContext.showExtraOfflineInfo;

    let binLength = props.binLength;
    const binInfo = props.bin;
    const azureDeviceId = binInfo?.deviceId;
    const binXl: number = binLength >= 3 ? 8 : (binLength >= 2 ? 12 : 24);
    const binLg: number = binLength >= 2 ? 12 : 24;

    const userTimeZoneOffset = getUserTimezoneOffset();
    // const initialState: State = {
    //     refreshing: false,
    // };
    // const [state, setState] = useState<State>(initialState);
    const history = useHistory<HistoryState>();
    const location = useLocation<HistoryState>();

    const azurePingAndDTOQuery = useDevicePingAndReport(props.bin?.deviceId!, userTimeZoneOffset, {
        enabled: (azureDeviceId?.length ?? 0) > 0,
    });

    const deviceBinDTOQuery = useBinStateFromDevice(
        {
            azureDeviceID: props?.bin?.deviceId!,
            binId: props?.bin?.id,
            userTimeZoneOffset,
        },
        { enabled: false });

    let bin = azurePingAndDTOQuery.data?.bin;

    const deviceNewer = (): boolean => {
        const onDeviceLastUpdated = deviceBinDTOQuery.data?.captureTimeUtc;
        const azureCaptureTime = azurePingAndDTOQuery.data?.bin?.captureTimeUtc;
        if (azureCaptureTime == null || onDeviceLastUpdated == null) {
            return false;
        }
        return onDeviceLastUpdated >= azureCaptureTime;

    };

    // Show the device binstate if it is newer only if the refresh button has been pressed
    if (deviceNewer() && deviceBinDTOQuery.isFetchedAfterMount) {
        // console.log(`using device data: ${binInfo.deviceId}`);
        bin = deviceBinDTOQuery.data;
    }
    else {
        // console.log(`using azure data: ${binInfo.deviceId}`);
        bin = azurePingAndDTOQuery.data?.bin;
    }

    const shouldGetMoreOfflineReasons = azurePingAndDTOQuery.data?.online === false;
    const offlineReportQuery = useOfflineReport(props.bin?.deviceId, shouldGetMoreOfflineReasons);
    const { data: offlineReport } = offlineReportQuery;

    // const updateBinFromDevice = async (): Promise<void> => {
    //     setState((prev) => { return { ...prev, refreshing: true }; });
    //     try {
    //         // todo. Fixme
    //         const abc = new AbortController();
    //         const binDTO = await BinApiService.getBinDetailFromDevice(binInfo?.deviceId!, binInfo.id, userTimeZoneOffset, abc.signal);
    //         if (binDTO == null) { }
    //         else {
    //             setState((prev) => { return { ...prev, bin: binDTO }; });
    //         }
    //     } catch (err) {
    //         console.error("problem getting binState from device", err);
    //     }
    //     finally {
    //         setState((prev) => { return { ...prev, refreshing: false }; });
    //     }
    // };



    const heaterSetPointText = (): string => {

        // use default heating mode if old snapshot version format
        const currentHeaterMode = bin?.grain?.heaterMode ?? HeaterMode.EnergyEff;
        if (currentHeaterMode === HeaterMode.Ambient) {
            return 'Air';
        }

        const heaterMinRecomTemp = bin?.grain?.heaterMinRecomTemp ?? null;
        const heaterMaxRecomTemp = bin?.grain?.heaterMaxRecomTemp ?? null;

        if (heaterMinRecomTemp == null || heaterMaxRecomTemp == null) {
            return '--';
        }

        return `${heaterMinRecomTemp} - ${heaterMaxRecomTemp} °F`;
    };


    const createBinDetails = (online: boolean) => {
        if (azurePingAndDTOQuery.data?.bin == null) {
            console.warn(`${binInfo?.deviceId} Bin data not supplied when creating bin details`);
        }
        const devicePing = azurePingAndDTOQuery.data?.devicePing;
        const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);

        let winWidth = window.innerWidth;
        let op;

        const renderCanvas = () => {

            if (isAdmin && !online && showExtraOfflineInfo) {
                return false;
            }
            return true;
        }

        let tagColor = '#ccbe8d';
        switch (bin?.operatingMode) {
            case 1:
                op = ' In Fill & Aerate Mode';
                break;
            case 2:
                op = 'In Pre Dry';
                break;
            case 3:
                op = 'In Dry Mode';
                break;
            case 31:
                op = 'In Top Dry';
                break;
            case 20:
                op = "In User Control Mode";
                break;
            case 101:
                op = 'In Self Check';
                break;
            case 100:
                op = 'In Fail Safe';
                tagColor = '#FF0000';
                break;
            case 4:
                op = 'In Aeration';
                break;
            case 5:
                op = 'In Unload';
                break;
            default:
                op = 'In Empty/Idle';
                break;
        }

        if (bin?.isPaused) {
            op += '- Paused:  ' + bin?.reasonForPause;
            tagColor = '#FBD900';

        }

        let canvasDem = winWidth >= 992 ? 320 : (winWidth >= 768 ? 300 : 240);
        let ifXl = winWidth >= 1200 ? window.innerWidth * .6 : (winWidth >= 992 ? window.innerWidth * .8 : window.innerWidth);
        return (
            <BinInfoContext.Provider value={binInfo}>
                <BinDTOContext.Provider value={deviceNewer() ? deviceBinDTOQuery?.data : azurePingAndDTOQuery?.data?.bin}>

                    <Card key="card"
                        title={<Row justify="start" align="middle">
                            <Col>
                                {binInfo.name}
                            </Col>
                            <Col style={{ paddingLeft: 8 }}>
                                <Tag color={tagColor} style={{ borderColor: '#000', color: '#000' }}>{op}</Tag>
                            </Col>
                        </Row>}
                        headStyle={{ background: '#939393', color: '#000000' }}

                        extra={<Row gutter={8} justify="end">
                            <Col>
                                <Button ghost={false}
                                    type="primary" disabled={deviceBinDTOQuery.isFetching} onClick={(event: any) => {
                                        event.stopPropagation();
                                        deviceBinDTOQuery.refetch();
                                    }}><RedoOutlined style={{ fontSize: "20px" }} spin={deviceBinDTOQuery.isFetching} /></Button>
                            </Col>
                            <Col>
                                <Button
                                    ghost={false}
                                    type="primary"
                                    onClick={() => {
                                        queryClient.setQueriesData(binDBKeys.binInfo(binInfo?.id), (oldData: any) => {
                                            if (binInfo?.id === undefined) {
                                                return oldData;
                                            }

                                            return binInfo;
                                        });
                                        const binStatsRoute = `${Routes.BIN_STATS}/${binInfo.id}`;

                                        history.push(binStatsRoute, {
                                            binInfo: binInfo,
                                        })
                                    }}>
                                    Details
                                </Button>
                            </Col>
                        </Row>
                        }>

                        <Row justify="center" style={{ paddingTop: -2 }}>
                            <Col span={23}>
                                {bin !== null ?
                                    <Space direction='horizontal' size="small" wrap>
                                        <Tooltip placement="bottom" title="This should update every 5 minutes." trigger="hover">
                                            <span style={azurePingAndDTOQuery.data?.snapshotOld ? {} : { background: 'FFFF00' }}>
                                                {'Last updated: ' + dayjs(bin?.captureTimeUtc).format('MM/DD/YYYY h:mm a')}
                                            </span>
                                        </Tooltip>
                                        <BinOfflineIndicator binDTO={deviceNewer() ? deviceBinDTOQuery?.data : azurePingAndDTOQuery?.data?.bin} />
                                    </Space>
                                    : null}
                            </Col>
                            <Col span={1}>
                                {
                                    devicePing == null ?
                                        <Tooltip placement="bottom" title="Pinging bin." trigger="hover"><Spin size="small" /></Tooltip>
                                        : devicePing ?
                                            <Tooltip placement="bottom" title="Connected to bin." trigger="hover">
                                                <CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip>
                                            :
                                            <Tooltip placement="bottom" title="Failed to connect to bin. The latest update was less than 10 minutes ago,
                             so this is likely a transient issue."
                                                trigger="hover"><CloseCircleTwoTone twoToneColor="#eb2f96" /></Tooltip>
                                }
                            </Col>
                        </Row>
                        <Row justify="center" >

                            {renderCanvas() && <Col>
                                <BinOverview2dGraphicContainer canvasDem={canvasDem} />
                            </Col>}
                            {!renderCanvas() && <Col xs={24}>
                                <OfflineInfoContent offlineReport={offlineReport!} />
                            </Col>
                            }
                        </Row>
                        <Row justify="center" gutter={[16, 16]}>
                            <Descriptions
                                bordered={true}
                                column={1}
                                size="small"
                                style={{ width: '100%', maxWidth: 320, paddingTop: 10 }}
                            >
                                <Item label="Fan">{formatBool(bin?.isFanOn, { true: 'On', false: 'Off', null: '--', bold: false })}</Item>
                                {binConfiguredWithHeaters(bin) && <Item label="Heater">{formatBool(isAnyHeaterOn(bin!), { true: 'On', false: 'Off', null: '--', bold: false })} </Item>}
                                {(bin?.hardwareYear ?? 0) > 2021 && (bin?.innerCO2 != null || bin?.outerCO2 != null) && <Item label="CO2 (PPM)">{formatNumber(bin?.cO2Level, { filler: "", decimalPlaces: 0 })} </Item>}
                                {(bin?.innerSonar != null || bin?.outerSonar != null) && <Item label={<>
                                    <Space direction='horizontal' wrap>
                                        <Typography.Text>Fill Level (%)</Typography.Text>
                                        <Tooltip title="Fill level is based on the headspace sensor" overlayStyle={{ minWidth: "300px" }} overlayInnerStyle={{ textAlign: 'center' }}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </Space>
                                </>}
                                >{formatNumber(bin?.grain?.percentFullCalculated, { decimalPlaces: 1, suffix: "%" })}</Item>}

                                {/* {binConfiguredWithHeaters(bin) && <Item
                            label="Set Point">{heaterSetPointText()}
                        </Item>} */}

                            </Descriptions>

                        </Row>

                        {<Row justify='center' gutter={[16, 16]} style={{ paddingTop: 16, paddingBottom: 16 }}>
                            <Col>
                                <Button style={{ width: '100%', overflow: 'hidden' }} onClick={() =>
                                    downloadAsciiBinState(bin!)}>Download Diagnostic File</Button>
                            </Col>
                        </Row>
                        }
                    </Card>
                </BinDTOContext.Provider>
            </BinInfoContext.Provider>
        );
    };

    const dristackModuleOnline = azurePingAndDTOQuery.data?.online;

    const showLoadingIndicator = azurePingAndDTOQuery.isInitialLoading || azurePingAndDTOQuery.isFetching;

    const binCardRef = useRef<HTMLDivElement>(null);

    useEffect(function ScrollCardIntoView() {
        if (binCardRef.current == null) {
            return;
        }
        const fromPrior = location.state?.binInfo?.id === binInfo.id;
        if (fromPrior) {
            binCardRef.current?.scrollIntoView({ behavior: "auto", block: 'center' });
        }
    }, [location.state?.binInfo?.id, binCardRef.current == null]);



    return (
        <Col ref={binCardRef} key={props.id + 5} xs={24} sm={24} md={24} lg={binLg} xl={binXl} style={{ paddingBottom: 20 }}>
            <Skeleton loading={showLoadingIndicator} active={true} paragraph={{ rows: 10 }}> {
                createBinDetails(dristackModuleOnline!)
            } </Skeleton>
        </Col >
    );
}
interface BinOverview2dGraphicContainerProps {
    canvasDem: number;
}
export const BinOverview2dGraphicContainer = (props: BinOverview2dGraphicContainerProps) => {
    const queryClient = useQueryClient();
    const history = useHistory();

    const binInfo = useBinInfoContext();
    const binDTOContext = useBinDTOContext();
    const binDTO = binDTOContext.binDTO;

    return <>
        {binDTO && <Tooltip placement="bottom" title="Go To Bin Details" trigger="hover"><a onClick={() => {
            queryClient.setQueriesData(binDBKeys.binInfo(binInfo?.id), (oldData: any) => {
                if (binInfo?.id === undefined) {
                    return oldData;
                }

                return binInfo;
            });
            const binStatsRoute = `${Routes.BIN_STATS}/${binInfo.id}`;

            history.push(binStatsRoute, {
                binInfo: binInfo,
            });
        }}
        >
            <Canvas width={props.canvasDem} height={props.canvasDem} binData={binDTOContext.binDTO!} />
        </a></Tooltip>}

    </>;
}

interface OfflineInfoContentProps {
    offlineReport: OfflineBinReportDTO | null | undefined;

}

export const OfflineInfoContent = (props: OfflineInfoContentProps) => {
    const binInfo = useBinInfoContext();
    const offlineReport = props.offlineReport;
    const ecDeviceQuery = useEcDeviceQuery(binInfo.deviceId, true);
    const ecDeviceStatus = ecDeviceIsOnline(ecDeviceQuery.data?.context?.connection?.status as ECConnectionStatus);

    // if the DB Bin.deviceId is ever != dristackmodule twin properties.desired.binName there will be issues in getting logs from the Azure folder
    const { handleLogClickSubmit } = useBinStateLogFunctions(binInfo.deviceId!);
    const deviceTwinLastActivityTimeDate = dayjs(offlineReport?.reportedPropertiesLastActivityTime);

    const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);
    let title = "Bin Error";
    if (ecDeviceStatus?.online === false) {
        title = "Bin Offline";
    }
    else if (offlineReport?.connectionState === "Disconnected") {
        title = "Dri-Stack Not Running";
    }
    return <>
        <Result status="warning" style={{ paddingTop: 20, paddingBottom: 20 }} title={title} />
        <b><u>Last Reported Info:</u></b> <br />
        <b>Mode:</b> {offlineReport?.currentMode}<br />
        <b>Routine:</b> {offlineReport?.currentRoutine}<br />
        <b>Reported Properties Activity:</b> {deviceTwinLastActivityTimeDate.isValid() ? deviceTwinLastActivityTimeDate?.format("L LTS") : undefined}<br />
        <b>Module Twin Activity:</b> {dayjs(offlineReport?.moduleTwinLastActivityTime)?.format("L LTS")}<br />
        <b>ConnectionState:</b> {offlineReport?.connectionState}<br />
        <b>Message:</b> {offlineReport?.message} <br />
        {isAdmin && <><b>EC Device Display Name:</b> {ecDeviceQuery.data?.context?.displayName} <br /></>}
        <b>Device Online Status:</b> {ecDeviceQuery.data?.context?.connection?.status} <br />
        <br /> <br />
        <div style={{ display: isAdmin ? 'block' : 'none' }} >
            <b>Logs</b><br />
            {isAdmin &&
                <Col span={64} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                    <Collapse>
                        <Panel header="Bin State by Date" key="1">
                            <DeviceLogRangeForm onSubmit={handleLogClickSubmit('BinState')} />
                        </Panel>
                        <Panel header="Grain State by Date" key="2">
                            <DeviceLogRangeForm onSubmit={handleLogClickSubmit('GrainState')} />
                        </Panel>
                        <Panel header="Run Logs by Date" key="3">
                            <DeviceLogRangeForm onSubmit={handleLogClickSubmit('Run')} />
                        </Panel>
                    </Collapse>
                </Col>}
        </div>
        {isAdmin && (ecDeviceStatus?.online || offlineReport?.connectionState === 'Connected') && <LogViewer deviceId={binInfo.deviceId!} />}
    </>
}

export const useBinStateLogFunctions = (deviceId: string) => {

    const handleLogClickSubmit = useCallback((type: LogType) => (dateStart: string, dateEnd: string) => {
        const clientFolder = deviceId;
        switch (type) {
            case "BinState":
                viewBinStateLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            case "GrainState":
                viewGrainStateLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            case "Run":
                viewRunLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
        }
    }, [deviceId]);

    return React.useMemo(() => {
        return {
            handleLogClickSubmit: handleLogClickSubmit,
        }
    }, [handleLogClickSubmit, deviceId]);
}

export default BinDetails;
